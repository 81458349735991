import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Tooltip } from "@mui/material";
import { useState } from "react";

const CopyAddress = ({ txnAddress }) => {
  const COPY_TEXT = "Copy";
  const COPIED_TEXT = "Copied";

  const [toolTipText, setToolTipText] = useState(COPY_TEXT);

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(txnAddress);
    setToolTipText(COPIED_TEXT);
  };

  return (
    <>
      <Tooltip title={toolTipText} enterTouchDelay={0} placement="top">
        <IconButton
          size="small"
          onClick={handleCopyAddress}
          onBlur={() => setToolTipText(COPY_TEXT)}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default CopyAddress;
