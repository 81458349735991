import { DashboardIcon, SwapIcon } from "assets";
import APP_ENDPOINTS from "constants/appEndpoints";

const NAV_LINKS = [
	{
		id: 1,
		navTitle: "Dashboard",
		navLink: APP_ENDPOINTS.DASHBOARD,
		navIcon: DashboardIcon,
	},
	{
		id: 2,
		navTitle: "Swap",
		navLink: APP_ENDPOINTS.SWAP,
		navIcon: SwapIcon,
	},
];

export default NAV_LINKS;
