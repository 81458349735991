import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Grid,
	MenuItem,
	OutlinedInput,
	Select,
	Typography,
} from "@mui/material";
import { ArrowIcon, InfoIcon, SwapIcon } from "assets";

import { TranslucentCard, TransparentCard } from "components/common";
import { NETWORKS } from "constants/networks";
import WALLET_ADDRESSES from "constants/walletAddresses";
import useDebounce from "hooks/useDebounce";
import useSwap from "hooks/useSwap";
import { useEffect } from "react";
const Swap = ({
	setActiveStep,
	toQuantity,
	fromCurrency,
	toCurrency,
	fromQuantity,
	setFromCurrency,
	setToCurrency,
	setFromQuantity,
	setToQuantity,
	setFromNetwork,
	setToNetwork,
	fromNetwork,
	toNetwork,
}) => {
	const { getSwap, getWithdrawFee } = useSwap(setToQuantity);

	/* handle from network change */
	const handleFromNetworkChange = (event) => {
		setFromNetwork(event.target.value);
		setFromCurrency(event.target.value);
	};

	/* handle to network change */
	const handleToNetworkChange = (event) => {
		setToNetwork(event.target.value);
		setToCurrency(event.target.value);
	};

	/* handle from currency change */
	const handleFromCurrencyChange = (event) => {
		setFromCurrency(event.target.value);
	};

	/* handle to currency change */
	const handleToCurrencyChange = (event) => {
		setToCurrency(event.target.value);
	};

	const handleFromQuantity = (event) => {
		const regex = /^[0-9]*(?:\.[0-9]{0,3})?$/;

		// if value is not blank, then test the regexgex

		if (event.target.value === "" || regex.test(event.target.value)) {
			setFromQuantity(event.target.value);
		}
	};

	const navigateNext = () => {
		/* check if user has selected either of the three currencies ETH,MATIC,BNB
		-if yes then sent them to step2 else to step three
		 */

		if (
			fromCurrency === "ETH" ||
			fromCurrency === "MATIC" ||
			fromCurrency === "BNB"
		) {
			setActiveStep((prevState) => prevState + 1);
		} else {
			setActiveStep((prevState) => prevState + 2);
		}
	};

	const swapPayload = {
		from: fromCurrency,
		fromQty: parseFloat(fromQuantity),
		to: toCurrency,
	};

	const debouncedQuantity = useDebounce(fromQuantity, 500);

	useEffect(() => {
		if (fromQuantity === "") {
			setToQuantity({ qty: "00", estimated_Time: "-" });
			return;
		} else {
			getWithdrawFee({ coin: fromCurrency });
		}
	}, [fromQuantity]);

	useEffect(() => {
		if (fromQuantity === "") return;
		getSwap(swapPayload);
	}, [toCurrency, fromCurrency]);

	useEffect(() => {
		if (debouncedQuantity > 0) {
			getSwap(swapPayload);
		}
	}, [debouncedQuantity]);

	return (
		<>
			<Typography variant='h2' mb={2}>
				Swap
			</Typography>
			<Grid container>
				<Grid item sm={5} xs={12}>
					<TransparentCard>
						<Grid container mb={2}>
							<Grid item xs={6}>
								<FormControl fullWidth>
									<Select
										value={fromNetwork}
										displayEmpty
										onChange={handleFromNetworkChange}
										sx={{
											borderRadius: "1.375rem !important",
											border: "1px solid #ffffff",
											boxShadow: "none",
											height: "44px",
											".MuiOutlinedInput-notchedOutline": { border: 0 },
										}}>
										{NETWORKS.filter((val) => val?.symbol !== toNetwork).map(
											(currency) => (
												<MenuItem value={currency?.symbol} key={currency?.id}>
													<Box display='flex' alignItems='center'>
														{currency.icon}
														<span style={{ marginLeft: "0.5rem" }}>
															{currency?.name}
														</span>{" "}
													</Box>
												</MenuItem>
											)
										)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item container justifyContent={"flex-end"} xs={6}></Grid>
						</Grid>

						<TranslucentCard>
							<OutlinedInput
								placeholder='00'
								value={fromQuantity}
								onChange={handleFromQuantity}
								fullWidth
								sx={{
									backgroundColor: "#3d4c78",
									"& fieldset": { border: "none" },
								}}
								inputProps={{
									style: {
										fontSize: 50,
									},
								}}
								endAdornment={
									<Select
										value={fromCurrency}
										displayEmpty
										onChange={handleFromCurrencyChange}
										sx={{
											".MuiOutlinedInput-notchedOutline": { border: 0 },
											"& .MuiFilledInput-underline:before": {
												borderBottom: "none",
											},
											"& .MuiFilledInput-underline:after": {
												borderBottom: "none",
											},
											"& .MuiFilledInput-underline:hover:not(.Mui-disabled):before":
												{
													borderBottom: "none",
												},
										}}>
										{WALLET_ADDRESSES[fromNetwork].currencies.map(
											(currency) => (
												<MenuItem value={currency?.symbol} key={currency?.id}>
													<Box display='flex' alignItems='center'>
														{currency.icon}
														<span style={{ marginLeft: "0.5rem" }}>
															{currency?.symbol}
														</span>{" "}
													</Box>
												</MenuItem>
											)
										)}
									</Select>
								}
							/>
							<FormLabel>
								≈{" "}
								{toQuantity.dollarValueFrom ? toQuantity.dollarValueFrom : "$0"}
							</FormLabel>
						</TranslucentCard>
					</TransparentCard>
					{/* swap currency */}
					<br />
					<TransparentCard
						borderRadius={8}
						height='60px'
						insideCardContent={false}>
						<Box
							display={"flex"}
							justifyContent='space-around'
							alignItems='center'
							sx={{ height: "100%" }}>
							{fromCurrency && (
								<Box display={"flex"} alignItems='center'>
									{WALLET_ADDRESSES[fromNetwork].currencies[0]?.icon}
									<Typography variant='h5'>
										{WALLET_ADDRESSES[fromNetwork].currencies[0]?.symbol}
									</Typography>
								</Box>
							)}

							<Box>
								<ArrowIcon />
							</Box>
							{toCurrency && (
								<Box display={"flex"} alignItems='center'>
									{WALLET_ADDRESSES[toNetwork].currencies[0]?.icon}
									<Typography variant='h5'>
										&nbsp;{WALLET_ADDRESSES[toNetwork].currencies[0]?.symbol}
									</Typography>
								</Box>
							)}
						</Box>
					</TransparentCard>
					<Button
						sx={{
							background:
								"linear-gradient(183.76deg, #D9D9D9 -10.65%, #CA00FF -10.65%, #7E00FF 120.65%)",
							borderRadius: "1.25rem",
							width: "17.1875rem",
							height: "3.75rem",
							border: "none",
							margin: "2rem 0",
						}}
						onClick={navigateNext}
						disabled={toQuantity?.qty === "00"}>
						<Typography variant='subtitle2'>Next</Typography>
					</Button>
				</Grid>
				<Grid
					item
					sm={2}
					xs={12}
					flexDirection='column'
					container
					mt={13}
					alignItems='center'>
					<SwapIcon height='80' width='102' />
				</Grid>
				<Grid item sm={5} xs={12}>
					<TransparentCard>
						<Grid container alignItems={"center"} mb={2}>
							<Grid item xs={6}>
								<FormControl fullWidth>
									<Select
										value={toNetwork}
										displayEmpty
										onChange={handleToNetworkChange}
										sx={{
											borderRadius: "1.375rem !important",
											border: "1px solid #ffffff",
											height: "44px",
										}}>
										{NETWORKS.filter((val) => val?.symbol !== fromNetwork).map(
											(currency) => (
												<MenuItem value={currency?.symbol} key={currency?.id}>
													<Box display='flex' alignItems='center'>
														{currency.icon}
														<span style={{ marginLeft: "0.5rem" }}>
															{currency?.name}
														</span>{" "}
													</Box>
												</MenuItem>
											)
										)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item container justifyContent={"flex-end"} xs={6}></Grid>
						</Grid>

						<TranslucentCard>
							<OutlinedInput
								disabled
								value={toQuantity?.qty}
								placeholder='00'
								onChange={handleFromQuantity}
								fullWidth
								sx={{
									backgroundColor: "#3d4c78",
									"& fieldset": { border: "none" },
								}}
								inputProps={{
									style: {
										fontSize: 50,
									},
								}}
								endAdornment={
									<Select
										value={toCurrency}
										displayEmpty
										onChange={handleToCurrencyChange}
										sx={{
											".MuiOutlinedInput-notchedOutline": { border: 0 },
											"& .MuiFilledInput-underline:before": {
												borderBottom: "none",
											},
											"& .MuiFilledInput-underline:after": {
												borderBottom: "none",
											},
											"& .MuiFilledInput-underline:hover:not(.Mui-disabled):before":
												{
													borderBottom: "none",
												},
										}}>
										{WALLET_ADDRESSES[toNetwork].currencies.map((currency) => (
											<MenuItem value={currency?.symbol} key={currency?.id}>
												<Box display='flex' alignItems='center'>
													{currency.icon}
													<span style={{ marginLeft: "0.5rem" }}>
														{currency?.symbol}
													</span>{" "}
												</Box>
											</MenuItem>
										))}
									</Select>
								}
							/>

							<FormLabel>
								≈ {toQuantity.dollarValueTo ? toQuantity.dollarValueTo : "$0"}
							</FormLabel>
						</TranslucentCard>
					</TransparentCard>
				</Grid>
			</Grid>

			<br />
			<Box display='flex' justifyContent='space-between'>
				<Typography color='white' variant='body'>
					Bridge Fee: 0%
				</Typography>
				<Typography
					component={Box}
					display='flex'
					color='white'
					variant='body'
					alignItems={"center"}>
					Price Impact:
					{toQuantity?.priceImpact
						? `${toQuantity?.priceImpact} %`
						: "- %"}{" "}
					&nbsp;
					<InfoIcon />
				</Typography>
				<Typography color='white' variant='body'>
					Est. Time : {toQuantity?.estimated_Time} mins.
				</Typography>
				<Typography color='white' variant='body'>
					Minimum Received:{" "}
					{toQuantity.qty !== "00"
						? parseFloat(toQuantity?.qty - toQuantity?.qty * 0.001)
						: " -"}
				</Typography>
			</Box>
		</>
	);
};

export default Swap;
