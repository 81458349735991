function RippleIcon({ width = 32, height = 32 }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 32 32'>
			<g id='xrp' transform='translate(-0.028)'>
				<rect
					id='Rectangle_1711'
					data-name='Rectangle 1711'
					width={width}
					height={height}
					transform='translate(0.028)'
					fill='none'
				/>
				<path
					id='Union_18'
					data-name='Union 18'
					d='M15.515,14.958a.3.3,0,0,1-.2-.062q-1.82-1.8-3.632-3.6a8.128,8.128,0,0,0-.769-.709,3.17,3.17,0,0,0-4.192.27c-1.364,1.344-2.717,2.7-4.078,4.042a.241.241,0,0,1-.154.063c-.792,0-1.585,0-2.377,0a.127.127,0,0,1-.064-.014c.506-.525,1.011-1.07,1.536-1.595C2.844,12.1,4.123,10.868,5.363,9.6a5.1,5.1,0,0,1,7.223-.013C14.3,11.3,16.031,13,17.754,14.707c.07.069.141.136.246.236-.1.008-.165.016-.229.016l-1.128,0ZM5.448,5.4Q2.782,2.781.13.146C.1.113.067.078,0,.007.77.007,1.495.02,2.217,0a.826.826,0,0,1,.674.277C4.144,1.541,5.412,2.79,6.681,4.039a3.2,3.2,0,0,0,4.62-.012C12.58,2.761,13.863,1.5,15.134.224A.668.668,0,0,1,15.669,0c.75.014,1.5.006,2.286.006-.05.06-.079.1-.114.135Q15.183,2.771,12.523,5.4a5.011,5.011,0,0,1-7.074,0Z'
					transform='translate(7.028 9)'
					fill='#4891ff'
				/>
			</g>
		</svg>
	);
}

export default RippleIcon;
