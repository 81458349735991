import { createTheme } from "@mui/material";
import colors from "styles/exports.module.scss";
import { overrides } from "theme/Overrides";
const { typography, components } = overrides;

export const theme = createTheme({
	typography,
	palette: {
		mode: "dark",
		primary: { main: colors.primary },
	},
	components,
});
