import {
	AptosIcon,
	BinanceIcon,
	BitcoinIcon,
	CardanoIcon,
	EthereumIcon,
	LitecoinIcon,
	MoneroIcon,
	PolkadotIcon,
	PolygonIcon,
	RippleIcon,
	SolanaIcon,
	StellarIcon,
	TronIcon,
} from "assets";
export const NETWORKS = [
	{ id: 1, name: "Aptos", symbol: "APT", icon: <AptosIcon /> },
	{ id: 2, name: "Binance", symbol: "BNB", icon: <BinanceIcon /> },
	{ id: 3, name: "Bitcoin", symbol: "BTC", icon: <BitcoinIcon /> },
	{ id: 4, name: "Cardano", symbol: "ADA", icon: <CardanoIcon /> },
	{ id: 5, name: "Ethereum", symbol: "ETH", icon: <EthereumIcon /> },
	{ id: 6, name: "Litecoin", symbol: "LTC", icon: <LitecoinIcon /> },
	{ id: 7, name: "Polygon", symbol: "MATIC", icon: <PolygonIcon /> },
	{ id: 8, name: "Monero", symbol: "XMR", icon: <MoneroIcon /> },
	{ id: 9, name: "Polkadot", symbol: "DOT", icon: <PolkadotIcon /> },
	{ id: 10, name: "Ripple", symbol: "XRP", icon: <RippleIcon /> },
	{ id: 11, name: "Solana", symbol: "SOL", icon: <SolanaIcon /> },
	{ id: 12, name: "Stellar ", symbol: "XLM", icon: <StellarIcon /> },
	{ id: 13, name: "Tron", symbol: "TRX", icon: <TronIcon /> },
];
