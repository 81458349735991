const API_ENDPOINT = {
	BASE_URL: "https://temp.softcomputingindia.com",
	CREATE_SWAP: "/createSwap",
	GET_USER_TRADES: "/getUserTrades",
	GET_SWAP_DETAILS: "/getSwapDetails",
	GET_SWAP_QTY: "/getSwapQty",
	GET_WITHDRAW_FEE: "/getWithdrawFee",
};

export default API_ENDPOINT;
