import {
	Box,
	Container,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from "@mui/material";
import { Logo } from "assets";
import NAV_LINKS from "constants/navLinks";
import { Link, useLocation } from "react-router-dom";

const LeftSidebar = ({ children }) => {
	const drawerWidth = 240;
	const location = useLocation();

	return (
		<Box sx={{ display: "flex" }}>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					"& .MuiDrawer-paper": {
						width: drawerWidth,
						boxSizing: "border-box",
					},
				}}
				variant='permanent'
				anchor='left'>
				<Box display='flex' justifyContent='center' alignItems='center'>
					<img src={Logo} alt='logo' width='100%' height='100%'></img>
				</Box>

				<List sx={{ paddingTop: "3rem" }}>
					{NAV_LINKS.map((item) => {
						let isActiveLink = item.navLink === location.pathname;
						return (
							<ListItem key={item.id} disablePadding>
								<ListItemButton component={Link} to={item.navLink}>
									<ListItemIcon>
										<item.navIcon fill={isActiveLink ? "gradient" : "white"} />
									</ListItemIcon>
									<ListItemText
										primary={item.navTitle}
										sx={{ color: isActiveLink ? "#b158fd" : "white" }}
									/>
								</ListItemButton>
							</ListItem>
						);
					})}
				</List>
			</Drawer>
			<Box component='main' sx={{ flexGrow: 1, p: 2 }}>
				<Box display={"flex"} justifyContent='flex-end'>
					<Typography color='white' variant='subtitle1'>
						{localStorage.getItem("userId") != null &&
							`UID: ${localStorage.getItem("userId")}`}
					</Typography>{" "}
				</Box>
				{/* <Toolbar /> */}
				<Container maxWidth='xl'>{children}</Container>
			</Box>
		</Box>
	);
};

export default LeftSidebar;
