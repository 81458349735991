function MaticIcon({ width = 32, height = 32 }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 32 32'>
			<g id='matic' transform='translate(-1082 -470.799)'>
				<rect
					id='Rectangle_3256'
					data-name='Rectangle 3256'
					width={width}
					height={height}
					transform='translate(1082 470.799)'
					fill='none'
				/>
				<g id='polygon-matic-logo' transform='translate(1088 478)'>
					<path
						id='Path_44345'
						data-name='Path 44345'
						d='M15.144,5.279a1.331,1.331,0,0,0-1.253,0L10.966,7,8.982,8.1,6.11,9.822a1.331,1.331,0,0,1-1.253,0L2.611,8.464a1.273,1.273,0,0,1-.627-1.1V4.756a1.288,1.288,0,0,1,.627-1.1L4.856,2.354a1.331,1.331,0,0,1,1.253,0L8.355,3.712a1.273,1.273,0,0,1,.627,1.1V6.532l1.984-1.149V3.608a1.288,1.288,0,0,0-.627-1.1L6.162.057a1.331,1.331,0,0,0-1.253,0L.627,2.563A1.149,1.149,0,0,0,0,3.608V8.516a1.288,1.288,0,0,0,.627,1.1l4.23,2.454a1.331,1.331,0,0,0,1.253,0L8.982,10.4l1.984-1.149,2.872-1.671a1.331,1.331,0,0,1,1.253,0l2.245,1.305a1.273,1.273,0,0,1,.627,1.1v2.611a1.288,1.288,0,0,1-.627,1.1l-2.193,1.305a1.331,1.331,0,0,1-1.253,0l-2.245-1.305a1.273,1.273,0,0,1-.627-1.1V10.918L9.034,12.067V13.79a1.288,1.288,0,0,0,.627,1.1l4.23,2.454a1.331,1.331,0,0,0,1.253,0l4.23-2.454A1.273,1.273,0,0,0,20,13.79V8.83a1.288,1.288,0,0,0-.627-1.1Z'
						transform='translate(0 0.1)'
						fill='#8247e5'
					/>
				</g>
			</g>
		</svg>
	);
}

export default MaticIcon;
