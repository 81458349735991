import { ThemeProvider } from "@mui/material";
import { LeftSidebar } from "components";
import APP_ENDPOINTS from "constants/appEndpoints";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppRoutes from "routes/AppRoutes";
import { theme } from "theme";

function App() {
	const navigate = useNavigate();
	const location = useLocation();
	/* on every mount redirect user to /swap */
	useEffect(() => {
		if (location.pathname !== "/") return;
		navigate(APP_ENDPOINTS.SWAP);
	}, [location.pathname, navigate]);

	useEffect(() => {
		if (localStorage.getItem("userId") != null) return;
		const currentTimeStamp = Date.now();
		const userId = "KFT_".concat(currentTimeStamp);
		localStorage.setItem("userId", userId);
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<ToastContainer
				position='top-center'
				autoClose={2000}
				hideProgressBar
				newestOnTop
				closeOnClick
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme='dark'
				closeButton={false}
			/>
			<LeftSidebar>
				<AppRoutes />
			</LeftSidebar>
		</ThemeProvider>
	);
}

export default App;
