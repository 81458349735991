import { Box, Button, Typography } from "@mui/material";
import WALLET_ADDRESSES from "constants/walletAddresses";
import { useEffect, useState } from "react";
import web3 from "web3";

const StepTwo = ({
	fromQuantity,
	fromCurrency,
	setActiveStep,
	setTxnHash,
	setReceivingAddress,
	toCurrency,
}) => {
	const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(false);
	const [currentChainId, setCurrentChainId] = useState(null);
	const [isChainMismatch, setIsChainMismatch] = useState(false);
	const [currentAccount, setCurrentAccount] = useState(
		window?.ethereum?.selectedAddress
	);

	const addressForDeposit = WALLET_ADDRESSES[fromCurrency]?.address;
	const chainIdForDeposit = WALLET_ADDRESSES[fromCurrency]?.chainId;

	const hexOfFromQuantity = web3.utils.numberToHex(
		fromQuantity * Math.pow(10, 18)
	);
	/* if user has MATIC or ETH in from and to then set recievng address */
	if (
		(toCurrency === "MATIC" || toCurrency === "ETH" || toCurrency === "BNB") &&
		(fromCurrency === "MATIC" ||
			fromCurrency === "ETH" ||
			fromCurrency === "BNB")
	) {
		setReceivingAddress(window?.ethereum?.selectedAddress);
	}
	/* check if metamask is installed on browser or not */
	useEffect(() => {
		if (window.ethereum != null) {
			// console.log(window.ethereum);
			setIsMetaMaskInstalled(true);
		}
	}, []);

	/* check if metamask is connected or not */

	/* get current chain id of user;s metamask and compare it with the chain id of selected token */
	const getChainId = async () => {
		let currentChainId = await window.ethereum.request({
			method: "eth_chainId",
		});
		setCurrentChainId(currentChainId);
	};

	useEffect(() => {
		getChainId();
	}, []);

	useEffect(() => {
		// now match the chain id from user's metamask and the selected token's chain id if they are a match then continue else ask user to change the chain on metamask
		if (chainIdForDeposit !== currentChainId) {
			setIsChainMismatch(true);
		} else {
			setIsChainMismatch(false);
		}
	}, [currentChainId, chainIdForDeposit]);

	/* handle chain change */
	window.ethereum.on("chainChanged", (chainId) => setCurrentChainId(chainId)); //? sending network ID to local state every time user changes his/her wallet

	/* check if user has enough eth in account */
	const transactionParameters = {
		nonce: "0x00", // ignored by MetaMask
		gasPrice: "0x2e90edd000", // customizable by user during MetaMask confirmation.
		gas: "0x5208", // customizable by user during MetaMask confirmation.
		to: addressForDeposit, // Required except during contract publications.
		from: currentAccount, // must match user's active address.
		value: hexOfFromQuantity, // Only required to send ether to the recipient from the initiating external account.
		chainId: chainIdForDeposit, // Ued to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
	};
	const onContinue = () => {
		// txHash is a hex string
		// As with any RPC call, it may throw an error
		window.ethereum
			.request({
				method: "eth_sendTransaction",
				params: [transactionParameters],
			})
			.then((data) => {
				/* once transaction is successful store txn hash in state and proceed to next step */
				setTxnHash(data);
				setActiveStep((prevState) => prevState + 1);
			})
			.catch((error) => console.log(error));
	};

	/* when user switched account */
	window.ethereum.on("accountsChanged", handleAccountsChanged);

	// For now, 'eth_accounts' will continue to always return an array
	function handleAccountsChanged(accounts) {
		if (accounts.length === 0) {
			// MetaMask is locked or the user has not connected any account
		} else if (accounts[0] !== currentAccount) {
			setCurrentAccount(accounts[0]);
			// Do any other work!
		}
	}

	const handleConnect = () => {
		window.ethereum.request({ method: "eth_requestAccounts" });
	};

	const handleBackButtonClick = () => {
		setActiveStep((prevState) => prevState - 1);
	};
	// console.log("connection status?", window.ethereum.isConnected());

	return isMetaMaskInstalled ? (
		currentAccount != null ? (
			isChainMismatch ? (
				<>
					<Typography variant='h5' color='white' gutterBottom>
						Please switch to <strong>{fromCurrency} </strong>in your metamask to
						proceed.
					</Typography>
					<Button onClick={handleBackButtonClick}>Back</Button>
				</>
			) : (
				<Box sx={{ py: 10 }}>
					{/* if address is present means user has connected to metamask */}
					{currentAccount != null && (
						<>
							<Typography variant='h5' color='white' gutterBottom>
								Account connected : <strong>{currentAccount}</strong>
							</Typography>
						</>
					)}

					<Typography variant='h6' color='white' gutterBottom>
						Please make sure you have correct account connected and hit continue
					</Typography>
					<Button onClick={onContinue}>Continue</Button>
				</Box>
			)
		) : (
			<>
				<Typography variant='h6' color='white' gutterBottom>
					Please connect you wallet to proceed
				</Typography>
				<Button onClick={handleConnect}>Connect metamask</Button>
			</>
		)
	) : (
		<>
			<Typography variant='h5' color='white' gutterBottom>
				Metamask extension not found please try again after installing metamask
			</Typography>
			<Button onClick={handleBackButtonClick}>Back</Button>
		</>
	);
};

export default StepTwo;
