const BitcoinIcon = ({ width = 32, height = 32 }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			id='btc'
			width={width}
			height={height}
			viewBox='0 0 32 32'>
			<rect
				id='Rectangle_1707'
				data-name='Rectangle 1707'
				width={width}
				height={height}
				fill='none'
			/>
			<path
				id='BTC-2'
				data-name='BTC'
				d='M13.348,11.42a2.952,2.952,0,0,0,2.194-2.245c.5-1.866-.958-3.859-3.026-4.414-.151-.05-.051,0-.2-.05l.731-2.749L11.33,1.508l-.706,2.749L9.287,3.9l.681-2.749L8.278.7,7.547,3.45l-3.48-.958L3.512,4.61,5.1,5.039a.727.727,0,0,1,.479.908L3.537,13.564a.738.738,0,0,1-.883.555l-1.185-.328L.51,15.733l3.455.933-.731,2.749L5,19.894l.731-2.749,1.337.353-.731,2.749,1.715.454.731-2.749c2.522.681,5.549.328,6.356-2.724A3.233,3.233,0,0,0,13.348,11.42ZM8.707,6c.025,0,.2.05,1.942.53A1.5,1.5,0,1,1,9.867,9.4l-1.942-.53Zm-.1,9.71c-1.11-.3-2.32-.631-2.32-.631L7.32,11.143s.53.151,2.346.631a2.039,2.039,0,0,1,1.69,2.547A2.017,2.017,0,0,1,8.606,15.707Z'
				transform='translate(7.489 5.299)'
				fill='#f39325'
			/>
		</svg>
	);
};

export default BitcoinIcon;
