import { Swap, SwapDone } from "components";
import useInterval from "hooks/useInterval";
import useSwap from "hooks/useSwap";
import { useEffect, useState } from "react";
import StepTwo from "./stepTwo/StepTwo";

const SwapForm = () => {
	const [statusStep, setStatusStep] = useState(1);
	const [showStatusModal, setShowStatusModal] = useState(false);

	const [fromCurrency, setFromCurrency] = useState("BTC");

	const [fromNetwork, setFromNetwork] = useState("BTC");
	const [toNetwork, setToNetwork] = useState("ADA");
	const [toCurrency, setToCurrency] = useState("ADA");
	const [fromQuantity, setFromQuantity] = useState("");
	const [toQuantity, setToQuantity] = useState("00");
	const [txnHash, setTxnHash] = useState("");
	const [receivingAddress, setReceivingAddress] = useState("");
	const [memo, setMemo] = useState(null);
	const [activeStep, setActiveStep] = useState(1);

	const { createSwap, getSwapDetails, lastTxnId, swapStatus, txnData } =
		useSwap();

	const handleDone = () => {
		const uid = localStorage.getItem("userId");

		const payload = {
			uid,
			from: fromCurrency,
			fromQty: parseFloat(fromQuantity),
			to: toCurrency,
			toQty: toQuantity.qty,
			txHash: txnHash,
			withdrawAddress: receivingAddress,
			memo,
		};
		createSwap(payload);

		/* reset state to default */
		setFromCurrency("BTC");
		setToCurrency("ADA");
		setFromQuantity("");
		setTxnHash("");
		setReceivingAddress("");
		setToQuantity("00");
	};

	/* useEffect that checks for transaction id in local storage */

	useEffect(() => {
		if (localStorage.getItem("last_txn_id") === "null" && lastTxnId == null)
			return;
		getSwapDetails({ id: lastTxnId });
	}, [lastTxnId]);

	useEffect(() => {
		if (Object.keys(txnData).length > 0) {
			setShowStatusModal(true);
		} else {
			setShowStatusModal(false);
		}
	}, [txnData]);

	useInterval(() => {
		if (
			localStorage.getItem("last_txn_id") === "null" ||
			localStorage.getItem("last_txn_id") === null
		)
			return;
		getSwapDetails({ id: lastTxnId });
	}, 5000);

	/* if last txn's status is success then clear the transaction id from local storage */
	useEffect(() => {
		if (swapStatus) {
			setActiveStep(3);
			setShowStatusModal(true);

			switch (swapStatus) {
				case "INITIALIZED":
					setStatusStep(1);

					break;
				case "DEPOSIT_COMPLETE":
					setStatusStep(2);

					break;
				case "IN_PROGRESS":
					setStatusStep(3);

					break;
				case "EXECUTED":
					setStatusStep(4);

					break;
				case "WITHDRAWAL_COMPLETE":
				case "CANCELLED":
					setStatusStep(5);
					break;

				default:
					setStatusStep(1);

					break;
			}
		} else {
			setShowStatusModal(false);
		}
	}, [swapStatus]);

	const renderStep = () => {
		switch (activeStep) {
			case 1:
				return (
					<Swap
						setActiveStep={setActiveStep}
						toQuantity={toQuantity}
						fromQuantity={fromQuantity}
						toCurrency={toCurrency}
						fromCurrency={fromCurrency}
						setToQuantity={setToQuantity}
						setFromQuantity={setFromQuantity}
						setToCurrency={setToCurrency}
						setFromCurrency={setFromCurrency}
						setFromNetwork={setFromNetwork}
						setToNetwork={setToNetwork}
						fromNetwork={fromNetwork}
						toNetwork={toNetwork}
					/>
				);

			case 2:
				return (
					<StepTwo
						fromQuantity={fromQuantity}
						fromCurrency={fromCurrency}
						toCurrency={toCurrency}
						setActiveStep={setActiveStep}
						setTxnHash={setTxnHash}
						setReceivingAddress={setReceivingAddress}
						fromNetwork={fromNetwork}
						toNetwork={toNetwork}
					/>
				);

			case 3:
				return (
					<SwapDone
						setActiveStep={setActiveStep}
						txnHash={txnHash}
						setTxnHash={setTxnHash}
						receivingAddress={receivingAddress}
						setReceivingAddress={setReceivingAddress}
						handleDone={handleDone}
						fromCurrency={fromCurrency}
						statusStep={statusStep}
						showStatusModal={showStatusModal}
						setShowStatusModal={setShowStatusModal}
						setStatusStep={setStatusStep}
						lastTxnId={lastTxnId}
						txnData={txnData}
						swapStatus={swapStatus}
						fromNetwork={fromNetwork}
						toNetwork={toNetwork}
						setMemo={setMemo}
						memo={memo}
						toCurrency={toCurrency}
					/>
				);

			default:
				break;
		}
	};

	return renderStep();
};

export default SwapForm;
