function StellarIcon({ width = 32, height = 32 }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 32 32'>
			<g id='xlm' transform='translate(-0.028)'>
				<rect
					id='Rectangle_1717'
					data-name='Rectangle 1717'
					width={width}
					height={height}
					transform='translate(0.028)'
					fill='none'
				/>
				<g
					id='Group_5017'
					data-name='Group 5017'
					transform='translate(6.555 8.001)'>
					<path
						id='Path_133'
						data-name='Path 133'
						d='M-786.414,89.637a.882.882,0,0,0,.088-.034l3.08-1.569,4.353-2.219,4.246-2.164,4.246-2.164,2.774-1.414.086-.042c.019.06.027,1.448.009,1.626l-.386.2c-.1.053-.209.105-.313.16a1.47,1.47,0,0,0-.763,1.429,7.362,7.362,0,0,1,.02.782,7.646,7.646,0,0,1-.235,1.737,7.917,7.917,0,0,1-2.006,3.609,7.912,7.912,0,0,1-3.141,2,7.752,7.752,0,0,1-1.757.39c-.217.022-.435.028-.653.042-.026,0-.052.007-.078.011h-.24a.65.65,0,0,0-.077-.011,7.813,7.813,0,0,1-1.253-.129,7.924,7.924,0,0,1-3.159-1.333l-.086-.063,1.494-.761a6.474,6.474,0,0,0,4.049.778,6.389,6.389,0,0,0,3.708-1.792,6.4,6.4,0,0,0,1.853-3.379,6.436,6.436,0,0,0,.074-2.164l-.11.054-1.835.936-7.283,3.712-6.629,3.379a.473.473,0,0,1-.074.024Z'
						transform='translate(786.414 -76.021)'
						fill='#4891ff'
					/>
					<path
						id='Path_134'
						data-name='Path 134'
						d='M-776.684,55.906a.612.612,0,0,0,.077.013,7.929,7.929,0,0,1,4.248,1.45l.09.065c-.027.015-.047.028-.068.038-.455.232-.911.463-1.365.7a.116.116,0,0,1-.125,0,6.394,6.394,0,0,0-2.12-.721,6.445,6.445,0,0,0-1.7-.04,6.426,6.426,0,0,0-3.8,1.749,6.406,6.406,0,0,0-1.936,3.532,6.643,6.643,0,0,0-.1,1.566c.007.151.027.3.042.453a.48.48,0,0,0,.01.049l15.892-8.1c.02.061.027,1.456.01,1.628l-.386.2-7.219,3.679-7.084,3.611-2.1,1.075-2.014,1.024a.531.531,0,0,1-.074.025v-1.63a.29.29,0,0,0,.045-.014c.232-.118.47-.228.7-.357a1.408,1.408,0,0,0,.746-1.215c.006-.119-.008-.239-.014-.359a8.247,8.247,0,0,1,.2-2.244,8.008,8.008,0,0,1,4.171-5.3,7.939,7.939,0,0,1,3.257-.852.468.468,0,0,0,.068-.013Z'
						transform='translate(786.414 -55.906)'
						fill='#4891ff'
					/>
				</g>
			</g>
		</svg>
	);
}

export default StellarIcon;
