import {
	Box,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Dialog,
	Grid,
	IconButton,
	Step,
	StepLabel,
	Stepper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import { CloseIcon } from "assets";

import { TranslucentCard } from "components/common";
import TXN_STATUS from "constants/transactionStatuses";
import WALLET_ADDRESSES from "constants/walletAddresses";

import dayjs from "dayjs";
import useDashboard from "hooks/useDashboard";
import useInterval from "hooks/useInterval";
import useSwap from "hooks/useSwap";
import { useEffect, useState } from "react";

const Dashboard = () => {
	const { getUserTrades, userData } = useDashboard();
	const { getSwapDetails, swapStatus } = useSwap();

	useEffect(() => {
		if (localStorage.getItem("userId") == null) return;
		getUserTrades();
	}, []);

	useInterval(() => {
		if (localStorage.getItem("userId") == null) return;
		getUserTrades();
	}, 5000);

	const [statusStep, setStatusStep] = useState(1);
	const [showStatusModal, setShowStatusModal] = useState(false);
	const [txnData, setTxnData] = useState({});
	const [tempTxnId, setTxnId] = useState("");

	/* if last txn's status is success then clear the transaction id from local storage */
	useEffect(() => {
		switch (swapStatus) {
			case "INITIALIZED":
				setStatusStep(1);

				break;
			case "DEPOSIT_COMPLETE":
				setStatusStep(2);

				break;
			case "IN_PROGRESS":
				setStatusStep(3);

				break;
			case "EXECUTED":
				setStatusStep(4);

				break;
			case "WITHDRAWAL_COMPLETE":
			case "CANCELLED":
				setStatusStep(5);
				break;

			default:
				setStatusStep(1);

				break;
		}
	}, [swapStatus]);

	const handleRowClick = (data) => {
		setShowStatusModal(true);
		setTxnId(data._id);
		setTxnData(data);
	};

	useInterval(() => {
		if (tempTxnId === "") return;
		getSwapDetails({ id: tempTxnId });
	}, 4000);

	return (
		<>
			<Grid container sx={{ mt: 4 }}>
				<TranslucentCard>
					<Grid container justifyContent='space-between'>
						<Grid item xs={12}>
							<Typography variant='h6'>Your Transactions :</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='caption'>
								All your performed transactions will appear here
							</Typography>
						</Grid>
					</Grid>

					<TableContainer sx={{ mt: 2 }}>
						<Table aria-label='simple table'>
							<TableHead>
								<TableRow sx={{ borderBottom: "2px solid" }}>
									<TableCell>
										<Typography variant='h6'>Started</Typography>
									</TableCell>
									<TableCell>
										<Typography variant='h6'>From Qty.</Typography>
									</TableCell>
									<TableCell>
										<Typography variant='h6'>From</Typography>
									</TableCell>
									<TableCell>
										<Typography variant='h6'>To Qty.</Typography>
									</TableCell>
									<TableCell>
										<Typography variant='h6'>To </Typography>
									</TableCell>
									<TableCell>
										<Typography variant='h6'>Txn. Hash</Typography>
									</TableCell>
									<TableCell>
										<Typography variant='h6'>Status</Typography>
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{userData?.map((txn) => (
									<TableRow
										key={txn._id}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
										hover
										onClick={() => handleRowClick(txn)}>
										<TableCell component='th' scope='row'>
											<Typography variant='subtitle2'>
												{dayjs(txn?.timestamp).format("DD\u00A0MMM\u00A0YYYY")}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant='subtitle2'>
												{txn?.fromQty}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant='subtitle2'>{txn?.from}</Typography>
										</TableCell>
										<TableCell>
											<Typography variant='subtitle2'>{txn?.toQty}</Typography>
										</TableCell>
										<TableCell>
											<Typography variant='subtitle2'>{txn?.to}</Typography>
										</TableCell>
										<TableCell>
											<Typography variant='subtitle2'>{txn?.txHash}</Typography>
										</TableCell>
										<TableCell>
											<Typography variant='subtitle2'>{txn?.status}</Typography>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</TranslucentCard>
			</Grid>
			<Dialog
				maxWidth='xl'
				open={showStatusModal}
				onClose={() => {
					setShowStatusModal(false);
					setTxnData({});
				}}>
				<Card sx={{ width: "50rem", backgroundColor: "#23174f" }}>
					<CardHeader
						title={
							<Box display='flex' justifyContent='space-between'>
								<Typography>Transaction Details</Typography>
								<IconButton onClick={() => setShowStatusModal(false)}>
									<CloseIcon />
								</IconButton>
							</Box>
						}
					/>
					<CardContent>
						{/* From */}
						<Box border={1} borderRadius={1} p={1} m={1}>
							<Box>
								<Typography gutterBottom variant='h6'>
									<strong>From</strong>{" "}
								</Typography>
							</Box>

							<Grid container>
								<Grid item xs={6} container alignItems={"center"}>
									{WALLET_ADDRESSES[txnData?.from]?.icon}{" "}
									<Typography gutterBottom>
										{WALLET_ADDRESSES[txnData?.from]?.name}
									</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									container
									alignItems={"center"}
									justifyContent='flex-end'>
									<Typography gutterBottom>{txnData?.fromQty}</Typography>
								</Grid>
							</Grid>

							<Grid container>
								<Grid item xs={6} container alignItems={"center"}>
									<Typography gutterBottom>Tx. Hash</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									container
									alignItems={"center"}
									justifyContent='flex-end'>
									<Typography gutterBottom noWrap>
										{txnData?.txHash}
									</Typography>
								</Grid>
							</Grid>

							<Grid container>
								<Grid item xs={6} container alignItems={"center"}>
									<Typography gutterBottom>Deposit Address</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									container
									alignItems={"center"}
									justifyContent='flex-end'>
									<Tooltip
										title={
											<Typography gutterBottom>
												{WALLET_ADDRESSES[txnData?.from]?.address}
											</Typography>
										}
										arrow>
										<Typography gutterBottom noWrap>
											{WALLET_ADDRESSES[txnData?.from]?.address}
										</Typography>
									</Tooltip>
								</Grid>
							</Grid>
						</Box>

						{/* To */}

						<Box border={1} borderRadius={1} p={1} m={1} mb={4}>
							<Typography gutterBottom variant='h6'>
								<strong>To</strong>{" "}
							</Typography>

							<Grid container>
								<Grid item xs={6} container alignItems={"center"}>
									{WALLET_ADDRESSES[txnData?.to]?.icon}{" "}
									<Typography gutterBottom>
										{WALLET_ADDRESSES[txnData?.to]?.name}
									</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									container
									alignItems={"center"}
									justifyContent='flex-end'>
									<Typography gutterBottom>{txnData?.toQty}</Typography>
								</Grid>
							</Grid>

							<Grid container>
								<Grid item xs={6} container alignItems={"center"}>
									<Typography gutterBottom>Tx. Hash</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									container
									alignItems={"center"}
									justifyContent='flex-end'>
									<Typography gutterBottom>-</Typography>
								</Grid>
							</Grid>

							<Grid container>
								<Grid item xs={6} container alignItems={"center"}>
									<Typography gutterBottom>Recipient Address</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									container
									alignItems={"center"}
									justifyContent='flex-end'>
									<Tooltip
										title={
											<Typography gutterBottom>
												{txnData?.withdrawAddress}
											</Typography>
										}
										arrow>
										<Typography gutterBottom noWrap>
											{txnData?.withdrawAddress}
										</Typography>
									</Tooltip>
								</Grid>
							</Grid>
						</Box>
						<Stepper activeStep={statusStep} alternativeLabel>
							{TXN_STATUS.map((txn) => {
								return (
									<Step
										key={txn.id}
										sx={{
											"& .MuiStepLabel-root .Mui-completed": {
												color: "success.main", // circle color (COMPLETED)
											},
											"& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
												{
													color: "success.main", // Just text label (COMPLETED)
												},
											"& .MuiStepLabel-root .Mui-active": {
												color: "secondary.main", // circle color (ACTIVE)
											},
											"& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
												{
													color: "common.white", // Just text label (ACTIVE)
												},
											"& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
												fill: "black", // circle's number (ACTIVE)
											},
										}}>
										<StepLabel>
											<Box
												display='flex'
												justifyContent='center'
												alignItems='center'>
												{statusStep < 5 && statusStep + 1 === txn.id && (
													<Box mr={0.5}>
														<CircularProgress size='1rem' color='secondary' />
													</Box>
												)}

												{swapStatus === "CANCELLED" ? "Cancelled" : txn.label}
											</Box>
										</StepLabel>
									</Step>
								);
							})}
						</Stepper>
					</CardContent>
				</Card>
			</Dialog>
		</>
	);
};

export default Dashboard;
