function AptosIcon({ width = 32, height = 22 }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			version='1.1'
			id='Layer_1'
			width={width}
			height={height}
			x='0px'
			y='0px'
			viewBox='0 0 128 128'>
			<g>
				<path d='M123.9,43H86l-7.6-7.8c-0.8-0.8-2.1-0.8-2.9,0L67.7,43H4.1c2.2-6.3,5.3-12.1,9.3-17.3h69.1l8.2-8.2c0.8-0.8,2.1-0.8,2.9,0   l7.9,8.2h13.2C118.6,30.9,121.8,36.7,123.9,43z' />
				<path d='M127.5,64c0,4.2-0.4,8.4-1.2,12.3H63.2l-7.6-7.8c-0.8-0.8-2.1-0.8-2.9,0l-7.8,7.8H1.7c-0.8-4-1.2-8.1-1.2-12.3   c0-1.7,0.1-3.4,0.2-5.2h58.9l8.5-8.5c0.8-0.8,2.1-0.8,2.9,0l8.2,8.5h48.1C127.4,60.6,127.5,62.2,127.5,64z' />
				<path d='M120.9,92.1c-2.8,5.7-6.5,10.9-10.8,15.5H38.2l-7.4-7.6c-0.8-0.8-2.1-0.8-2.9,0l-7.7,7.7h-2.3c-4.4-4.6-8-9.8-10.8-15.5   h29.7l8.5-8.5c0.8-0.8,2.1-0.8,2.9,0l8.2,8.5H120.9z' />
				<path
					fill='#fff'
					d='M110.1,107.7c-11.6,12.2-27.9,19.8-46.1,19.8s-34.5-7.6-46.1-19.8h2.3l7.7-7.7c0.8-0.8,2.1-0.8,2.9,0l7.4,7.6   H110.1z'
				/>
				<path
					fill='#fff'
					d='M114.7,25.7h-13.2l-7.9-8.2c-0.8-0.8-2.1-0.8-2.9,0l-8.2,8.2H13.3C24.9,10.4,43.3,0.5,64,0.5   C84.7,0.5,103.1,10.4,114.7,25.7z'
				/>
				<path
					fill='#fff'
					d='M127.3,58.8H79.2L71,50.3c-0.8-0.8-2.1-0.8-2.9,0l-8.5,8.5H0.7C1.2,53.3,2.3,48,4.1,43h63.6l7.8-7.8   c0.8-0.8,2.1-0.8,2.9,0L86,43h37.9C125.7,48,126.9,53.3,127.3,58.8z'
				/>
				<path
					fill='#fff'
					d='M126.3,76.3c-1.1,5.6-2.9,10.9-5.4,15.8H56.4l-8.2-8.5c-0.8-0.8-2.1-0.8-2.9,0l-8.5,8.5H7.1   c-2.5-4.9-4.3-10.2-5.4-15.8h43.2l7.8-7.8c0.8-0.8,2.1-0.8,2.9,0l7.6,7.8H126.3z'
				/>
			</g>
		</svg>
	);
}

export default AptosIcon;
