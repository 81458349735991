const DashboardIcon = ({ fill = "white" }) => {
	return (
		<svg
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M20.1667 10.5C17.4052 10.5 15.1667 8.26142 15.1667 5.5V5C15.1667 2.23858 17.4052 0 20.1667 0H23C25.7614 0 28 2.23858 28 5V5.5C28 8.26142 25.7614 10.5 23 10.5H20.1667ZM5 15.1667C2.23857 15.1667 0 12.9281 0 10.1667V5C0 2.23858 2.23858 0 5 0H7.83334C10.5948 0 12.8333 2.23858 12.8333 5V10.1667C12.8333 12.9281 10.5948 15.1667 7.83333 15.1667H5ZM20.1667 28C17.4052 28 15.1667 25.7614 15.1667 23V17.8333C15.1667 15.0719 17.4052 12.8333 20.1667 12.8333H23C25.7614 12.8333 28 15.0719 28 17.8333V23C28 25.7614 25.7614 28 23 28H20.1667ZM5 28C2.23857 28 0 25.7614 0 23V22.5C0 19.7386 2.23858 17.5 5 17.5H7.83334C10.5948 17.5 12.8333 19.7386 12.8333 22.5V23C12.8333 25.7614 10.5948 28 7.83333 28H5ZM2.33333 8.75C2.33333 11.0052 4.1615 12.8333 6.41667 12.8333C8.67183 12.8333 10.5 11.0052 10.5 8.75V6.41667C10.5 4.1615 8.67183 2.33333 6.41667 2.33333C4.1615 2.33333 2.33333 4.1615 2.33333 6.41667V8.75ZM17.5 21.5833C17.5 23.8385 19.3282 25.6667 21.5833 25.6667C23.8385 25.6667 25.6667 23.8385 25.6667 21.5833V19.25C25.6667 16.9948 23.8385 15.1667 21.5833 15.1667C19.3282 15.1667 17.5 16.9948 17.5 19.25V21.5833ZM17.5 5.25C17.5 6.86083 18.8058 8.16667 20.4167 8.16667H22.75C24.3608 8.16667 25.6667 6.86083 25.6667 5.25C25.6667 3.63917 24.3608 2.33333 22.75 2.33333H20.4167C18.8058 2.33333 17.5 3.63917 17.5 5.25ZM2.33333 22.75C2.33333 24.3608 3.63917 25.6667 5.25 25.6667H7.58334C9.19417 25.6667 10.5 24.3608 10.5 22.75C10.5 21.1392 9.19416 19.8333 7.58333 19.8333H5.25C3.63917 19.8333 2.33333 21.1392 2.33333 22.75Z'
				fill={fill === "white" ? "white" : "url(#gradient)"}
			/>
			<defs>
				<linearGradient
					id='gradient'
					x1='16'
					y1='2.02362e-09'
					x2='13.4358'
					y2='39.0125'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#D9D9D9' />
					<stop stopColor='#CA00FF' />
					<stop offset='1' stopColor='#7E00FF' />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default DashboardIcon;
