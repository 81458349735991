// INITIALIZED->DEPOSIT CONFIRMED->IN PROGRESS->EXECUTED->WITHDRAW COMPLETE
const TXN_STATUS = [
	{
		id: 1,
		status: "INITIALIZED",
		label: "Initialized",
	},
	{
		id: 2,
		status: "DEPOSIT_COMPLETE",
		label: "Deposit complete",
	},
	{
		id: 3,
		status: "IN_PROGRESS",
		label: "In Progress",
	},
	{
		id: 4,
		status: "EXECUTED",
		label: "Executed",
	},
	{
		id: 5,
		status: "WITHDRAWAL_COMPLETE",
		label: "Withdrawal complete",
	},
];
export default TXN_STATUS;
