function TronIcon({ width = 32, height = 32 }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 32 32'>
			<g id='tron' transform='translate(-15368.546 -7030)'>
				<rect
					id='Rectangle_3302'
					data-name='Rectangle 3302'
					width={width}
					height={height}
					transform='translate(15368.546 7030)'
					fill='none'
				/>
				<g id='tron-2' data-name='tron' transform='translate(15375.042 7036)'>
					<path
						id='Path_44574'
						data-name='Path 44574'
						d='M20.3,6.024c-.937-.866-2.234-2.187-3.291-3.125l-.062-.044a1.194,1.194,0,0,0-.347-.194h0C14.053,2.187,2.2-.029,1.969,0a.437.437,0,0,0-.181.069L1.728.115a.7.7,0,0,0-.162.262L1.55.418V.674c1.334,3.716,6.6,15.887,7.641,18.744.063.194.181.562.4.581h.05c.119,0,.625-.669.625-.669s9.05-10.975,9.965-12.144a2.956,2.956,0,0,0,.312-.462A.753.753,0,0,0,20.3,6.024ZM12.59,7.3l3.862-3.2,2.266,2.087Zm-1.5-.209-6.65-5.45L15.2,3.627Zm.6,1.428,6.806-1.1L10.716,16.8ZM3.537,2.187l7,5.937L9.522,16.805Z'
						transform='translate(-1.55 0.001)'
						fill='#ff060a'
					/>
				</g>
			</g>
		</svg>
	);
}

export default TronIcon;
