const SwapIcon = ({ height = "25", width = "28", fill = "white" }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 28 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				opacity='0.5'
				d='M6.54008 24.1991C6.08405 24.6445 5.35595 24.6445 4.89992 24.1991L1.66306 21.0381C-0.34579 19.0764 -0.345787 15.8455 1.66306 13.8837L4.89992 10.7227C5.35595 10.2774 6.08405 10.2774 6.54008 10.7227C7.01167 11.1833 7.01167 11.9417 6.54008 12.4023L5.97552 12.9536C4.72822 14.1717 5.59061 16.2891 7.33401 16.2891H14.0281C14.6753 16.2891 15.2 16.8137 15.2 17.4609C15.2 18.1081 14.6753 18.6328 14.0281 18.6328H7.33401C5.59061 18.6328 4.72823 20.7502 5.97552 21.9683L6.54008 22.5196C7.01167 22.9801 7.01167 23.7386 6.54008 24.1991ZM23.1001 14.2773C22.644 14.7226 21.9159 14.7226 21.4599 14.2773C20.9883 13.8167 20.9883 13.0583 21.4599 12.5977L22.0245 12.0464C23.2718 10.8283 22.4094 8.71094 20.666 8.71094H13.9719C13.3247 8.71094 12.8 8.18627 12.8 7.53906C12.8 6.89185 13.3247 6.36719 13.9719 6.36719H20.666C22.4094 6.36719 23.2718 4.24978 22.0245 3.03171L21.4599 2.48039C20.9883 2.01986 20.9883 1.26139 21.4599 0.800862C21.9159 0.355519 22.6441 0.355519 23.1001 0.800861L26.3369 3.96186C28.3458 5.92362 28.3458 9.1545 26.3369 11.1163L23.1001 14.2773Z'
				fill={fill === "white" ? "white" : "url(#gradient)"}
			/>
			<defs>
				<linearGradient
					id='gradient'
					x1='16'
					y1='2.02362e-09'
					x2='13.4358'
					y2='39.0125'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#D9D9D9' />
					<stop stopColor='#CA00FF' />
					<stop offset='1' stopColor='#7E00FF' />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default SwapIcon;
