import axios from "axios";
import API_ENDPOINT from "constants/apiEndpoints";

const useAxios = () => {
  const axiosInstance = axios.create({
    baseURL: API_ENDPOINT.BASE_URL,
  });
  const axiosRequest = ({ ...options }) => {
    const onSuccess = (response) => response;
    const onError = (error) => error;
    return axiosInstance(options).then(onSuccess).catch(onError);
  };
  return { axiosRequest };
};

export default useAxios;
