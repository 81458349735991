import API_ENDPOINT from "constants/apiEndpoints";
import { useState } from "react";
import { toast } from "react-toastify";
import useAxios from "utils/useAxios";

const useSwap = (setToQuantity) => {
	const { axiosRequest } = useAxios();

	const [lastTxnId, setLastTxnId] = useState(
		localStorage.getItem("last_txn_id") ?? null
	);
	const [txnData, setTxnData] = useState({});
	const [swapStatus, setSwapStatus] = useState("");
	const [withdrawFee, setWithdrawFee] = useState({});

	const createSwap = (data) => {
		axiosRequest({ method: "post", url: API_ENDPOINT.CREATE_SWAP, data })
			.then((res) => {
				if (res.data.message === "Swap created successfully") {
					toast.success(res.data.message);
					localStorage.setItem("last_txn_id", res.data.swapId);

					setLastTxnId(res.data.swapId);
				} else {
					toast.info(res.data);
				}
				return res;
			})
			.catch((error) => toast.error(error));
	};

	const getSwap = (data) => {
		axiosRequest({ method: "post", url: API_ENDPOINT.GET_SWAP_QTY, data })
			.then((res) => {
				if (res?.data === "Swap qty is less than $20") {
					toast.info(res.data);
				} else {
					setToQuantity(res.data.Quantity);
				}
			})
			.catch((error) => toast.error(error));
	};

	const getWithdrawFee = (data) => {
		axiosRequest({ method: "post", url: API_ENDPOINT.GET_WITHDRAW_FEE, data })
			.then((res) => setWithdrawFee(res.data))
			.catch((error) => toast.error(error));
	};

	const getSwapDetails = (data) => {
		axiosRequest({ method: "post", url: API_ENDPOINT.GET_SWAP_DETAILS, data })
			.then((res) => {
				setSwapStatus(res.data.status);
				setTxnData(res.data);
			})
			.catch((err) => console.log(err));
	};

	return {
		createSwap,
		getSwap,
		getSwapDetails,
		txnData,
		swapStatus,
		lastTxnId,
		getWithdrawFee,
		withdrawFee,
	};
};

export default useSwap;
