import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Dialog,
	Grid,
	IconButton,
	OutlinedInput,
	Step,
	StepLabel,
	Stepper,
	Tooltip,
	Typography,
} from "@mui/material";
import { CloseIcon, SwapIcon } from "assets";
import { TranslucentCard, TransparentCard } from "components/common";
import CopyAddress from "components/common/CopyAddress";
import TXN_STATUS from "constants/transactionStatuses";
import WALLET_ADDRESSES from "constants/walletAddresses";

const SwapDone = ({
	setActiveStep,
	txnHash,
	setTxnHash,
	receivingAddress,
	setReceivingAddress,
	handleDone,
	fromCurrency,
	statusStep,
	showStatusModal,
	setShowStatusModal,
	setStatusStep,
	txnData,
	swapStatus,
	setMemo,
	memo,
	toCurrency,
}) => {
	const handleTxnHash = (event) => {
		setTxnHash(event.target.value);
	};
	const handleReceivingAddressChange = (event) => {
		setReceivingAddress(event.target.value);
	};

	const handleBack = () => {
		/* check if user has selected either of the three currencies ETH,MATIC,BNB
		-if yes then sent them to step2 else to step one
		 */

		if (
			fromCurrency === "ETH" ||
			fromCurrency === "MATIC" ||
			fromCurrency === "BNB"
		) {
			setActiveStep((prevState) => prevState - 1);
		} else {
			setActiveStep((prevState) => prevState - 2);
		}
	};

	const handleOkButtonClick = () => {
		setActiveStep(1);
		localStorage.setItem("last_txn_id", null);
		setStatusStep(1);
		setShowStatusModal(false);
	};

	const handleMemoChange = (event) => {
		setMemo(event.target.value);
	};

	const depositAddress = WALLET_ADDRESSES[fromCurrency];

	return (
		<>
			<Typography variant='h2' mb={2}>
				Swap
			</Typography>
			<br />
			<Grid container>
				<Grid item xs={12}>
					<Typography color='white' variant='subtitle1' gutterBottom>
						Please make deposit to below address:
					</Typography>
				</Grid>
				<Grid container item xs={1} justifyContent='center' alignItems='center'>
					{depositAddress?.icon}
				</Grid>
				<Grid item xs={11}>
					<Typography color='white' variant='subtitle2'>
						Address: {depositAddress?.address}{" "}
						<CopyAddress txnAddress={depositAddress?.address} />
					</Typography>
					{depositAddress?.tag && (
						<Typography color='white' variant='subtitle2' gutterBottom>
							Tag: {depositAddress?.tag}
						</Typography>
					)}
				</Grid>
			</Grid>

			<br />
			<br />

			<Grid container alignItems='center'>
				<Grid item sm={5} xs={12}>
					<TransparentCard>
						<Typography variant='h6' color='white' gutterBottom>
							Enter the txn. hash
						</Typography>{" "}
						<TranslucentCard>
							<OutlinedInput
								placeholder='Provide txn hash after making deposit'
								value={txnHash}
								multiline
								rows={4}
								onChange={handleTxnHash}
								fullWidth
								sx={{
									backgroundColor: "#3d4c78",
									"& fieldset": { border: "none" },
								}}
							/>
						</TranslucentCard>
					</TransparentCard>
				</Grid>
				<Grid
					item
					sm={2}
					xs={12}
					container
					justifyContent='center'
					alignItems='center'>
					<SwapIcon height='80' width='102' />
				</Grid>
				<Grid item sm={5} xs={12}>
					<TransparentCard>
						<Typography gutterBottom variant='h6'>
							Receiving Address
						</Typography>

						<TranslucentCard>
							<OutlinedInput
								multiline
								rows={4}
								placeholder='Wallet where you wish to receive swapped currency'
								value={receivingAddress}
								onChange={handleReceivingAddressChange}
								fullWidth
								sx={{
									backgroundColor: "#3d4c78",
									"& fieldset": { border: "none" },
								}}
							/>
						</TranslucentCard>

						{/* show memo field when recieving currency is xlm */}
						{(toCurrency === "XLM" || toCurrency === "XRP") && (
							<>
								<Typography gutterBottom variant='h6'>
									Memo
								</Typography>

								<TranslucentCard>
									<OutlinedInput
										multiline
										rows={1}
										placeholder='memo'
										value={memo}
										onChange={handleMemoChange}
										fullWidth
										sx={{
											backgroundColor: "#3d4c78",
											"& fieldset": { border: "none" },
										}}
									/>
								</TranslucentCard>
							</>
						)}
					</TransparentCard>
				</Grid>
			</Grid>

			<Button
				sx={{
					background:
						"linear-gradient(183.76deg, #D9D9D9 -10.65%, #CA00FF -10.65%, #7E00FF 120.65%)",
					borderRadius: "1.25rem",
					width: "17.1875rem",
					height: "3.75rem",
					border: "none",
					margin: "2rem 0",
				}}
				onClick={handleDone}>
				<Typography variant='subtitle2'>Swap</Typography>
			</Button>

			<Button
				sx={{
					background: "#9087a6",
					borderRadius: "1.25rem",
					width: "12rem",
					height: "3.75rem",
					border: "none",
					margin: "2rem 0 2rem 2rem",
					"&:hover": {
						background: "#9087a6",
					},
				}}
				onClick={handleBack}>
				<Typography variant='subtitle2'>Back</Typography>
			</Button>

			{/* modal to show status */}
			<Dialog open={showStatusModal} maxWidth='xl'>
				<Card sx={{ width: "50rem", backgroundColor: "#23174f" }}>
					<CardHeader
						title={
							<Box display='flex' justifyContent='space-between'>
								<Typography>Transaction Details</Typography>
								<IconButton onClick={handleOkButtonClick}>
									<CloseIcon />
								</IconButton>
							</Box>
						}
					/>
					<CardContent>
						{/* From */}
						<Box border={1} borderRadius={1} p={1} m={1}>
							<Box>
								<Typography gutterBottom variant='h6'>
									<strong>From</strong>{" "}
								</Typography>
							</Box>

							<Grid container>
								<Grid item xs={6} container alignItems={"center"}>
									{WALLET_ADDRESSES[txnData?.from]?.icon}{" "}
									<Typography gutterBottom>
										{WALLET_ADDRESSES[txnData?.from]?.name}
									</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									container
									alignItems={"center"}
									justifyContent='flex-end'>
									<Typography gutterBottom>{txnData?.fromQty}</Typography>
								</Grid>
							</Grid>

							<Grid container>
								<Grid item xs={6} container alignItems={"center"}>
									<Typography gutterBottom>Tx. Hash</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									container
									alignItems={"center"}
									justifyContent='flex-end'>
									<Typography gutterBottom noWrap>
										{txnData?.txHash}
									</Typography>
								</Grid>
							</Grid>

							<Grid container>
								<Grid item xs={6} container alignItems={"center"}>
									<Typography gutterBottom>Deposit Address</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									container
									alignItems={"center"}
									justifyContent='flex-end'>
									<Tooltip
										title={
											<Typography gutterBottom noWrap>
												{WALLET_ADDRESSES[txnData?.from]?.address}{" "}
											</Typography>
										}
										arrow>
										<Typography gutterBottom noWrap>
											{WALLET_ADDRESSES[txnData?.from]?.address}{" "}
										</Typography>
									</Tooltip>
								</Grid>
							</Grid>
						</Box>

						{/* To */}

						<Box border={1} borderRadius={1} p={1} m={1} mb={4}>
							<Typography gutterBottom variant='h6'>
								<strong>To</strong>{" "}
							</Typography>

							<Grid container>
								<Grid item xs={6} container alignItems={"center"}>
									{WALLET_ADDRESSES[txnData?.to]?.icon}{" "}
									<Typography gutterBottom>
										{WALLET_ADDRESSES[txnData?.to]?.name}
									</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									container
									alignItems={"center"}
									justifyContent='flex-end'>
									<Typography gutterBottom>{txnData?.toQty}</Typography>
								</Grid>
							</Grid>

							<Grid container>
								<Grid item xs={6} container alignItems={"center"}>
									<Typography gutterBottom>Tx. Hash</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									container
									alignItems={"center"}
									justifyContent='flex-end'>
									<Typography gutterBottom>-</Typography>
								</Grid>
							</Grid>

							<Grid container>
								<Grid item xs={6} container alignItems={"center"}>
									<Typography gutterBottom>Recipient Address</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									container
									alignItems={"center"}
									justifyContent='flex-end'>
									<Tooltip
										title={
											<Typography gutterBottom>
												{txnData?.withdrawAddress}
											</Typography>
										}
										arrow>
										<Typography gutterBottom noWrap>
											{txnData?.withdrawAddress}
										</Typography>
									</Tooltip>
								</Grid>
							</Grid>
						</Box>
						<Stepper activeStep={statusStep} alternativeLabel>
							{TXN_STATUS.map((txn) => {
								return (
									<Step
										key={txn.id}
										sx={{
											"& .MuiStepLabel-root .Mui-completed": {
												color: "success.main", // circle color (COMPLETED)
											},
											"& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
												{
													color: "success.main", // Just text label (COMPLETED)
												},
											"& .MuiStepLabel-root .Mui-active": {
												color: "secondary.main", // circle color (ACTIVE)
											},
											"& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
												{
													color: "common.white", // Just text label (ACTIVE)
												},
											"& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
												fill: "black", // circle's number (ACTIVE)
											},
										}}>
										<StepLabel>
											<Box
												display='flex'
												justifyContent='center'
												alignItems='center'>
												{statusStep < 5 && statusStep + 1 === txn.id && (
													<Box mr={0.5}>
														<CircularProgress size='1rem' color='secondary' />
													</Box>
												)}

												{txn.label}
											</Box>
										</StepLabel>
									</Step>
								);
							})}
						</Stepper>
					</CardContent>
				</Card>
			</Dialog>
		</>
	);
};

export default SwapDone;
