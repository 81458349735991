import API_ENDPOINT from "constants/apiEndpoints";
import { useState } from "react";
import { toast } from "react-toastify";
import useAxios from "utils/useAxios";

const useDashboard = () => {
	const { axiosRequest } = useAxios();
	const uid = localStorage.getItem("userId");

	const [userData, setUserData] = useState([]);

	const getUserTrades = () =>
		axiosRequest({
			method: "post",
			url: API_ENDPOINT.GET_USER_TRADES,
			data: { uid },
		})
			.then((res) => setUserData(res?.data?.UserData))
			.catch((error) => toast.error(error));

	return {
		getUserTrades,
		userData,
	};
};

export default useDashboard;
