import { Box, Card, CardContent } from "@mui/material";

const TransparentCard = ({
	children,
	borderRadius = 8,
	bg = "#2c2056",
	width,
	p = 2,
	height,
	insideCardContent = true,
}) => {
	return (
		<Card
			variant='outlined'
			sx={{
				borderRadius,
				background: "linear-gradient(to bottom, #892AEC, #3635EC)",
				backdropFilter: "blur(1.5625rem)",
				width: width ? width : "100%",
				// maxWidth: 330,
				height,
			}}>
			{insideCardContent ? (
				<CardContent sx={{ background: bg, padding: `${p}rem` }}>
					{children}
				</CardContent>
			) : (
				<Box sx={{ background: bg, height: "100%" }}>{children}</Box>
			)}
		</Card>
	);
};

export default TransparentCard;
