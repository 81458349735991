import { Card, CardContent } from "@mui/material";

const TranslucentCard = ({ children, width = "100%" }) => {
	return (
		<Card
			variant='outlined'
			sx={{
				borderRadius: "1.25rem",
				background: "#3d4c78",
				// padding: "1rem",
				width: width,
			}}>
			<CardContent>{children}</CardContent>
		</Card>
	);
};

export default TranslucentCard;
