import { Dashboard, SwapForm } from "components";
import APP_ENDPOINTS from "constants/appEndpoints";
import { Route, Routes } from "react-router-dom";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={APP_ENDPOINTS.DASHBOARD} element={<Dashboard />}></Route>
      <Route path={APP_ENDPOINTS.SWAP} element={<SwapForm />}></Route>
    </Routes>
  );
};

export default AppRoutes;
