import { createTheme } from "@mui/material/styles";
import colors from "styles/exports.module.scss";

export const overrides = createTheme({
  typography: {
    allVariants: {
      fontFamily: ["Lato", "Istok Web"].join(", "),
    },
    h1: {
      fontFamily: "Lato",
      fontSize: "6.25rem",
      fontWeight: 300,
    },
    h2: {
      fontFamily: "Lato",
      fontSize: "3.75rem",
      fontWeight: 300,
      color: "#FFFFFF",
    },
    subtitle1: {
      fontFamily: "Lato",
      fontSize: "1.25rem",
      fontWeight: 700,
    },
    subtitle2: {
      fontFamily: "Lato",
      fontSize: "1rem",
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          border: "1px solid #FFFFFF",
          borderRadius: "1.375rem",
          color: "#ffffff",
          padding: "0.5rem 2rem",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
        paper: {
          backgroundColor: "transparent",
          padding: "0 3rem",
          borderRight: `1 px solid ${colors.primaryBg}`,
          boxShadow: `4px 0px 8px ${colors.borderShadow}`,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "12.5rem",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none",
          textShadow: "0px 8.26509px 8.26509px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            outline: "none",
            border: "none",
          },
        },
      },
    },
  },
});
