function SolanaIcon({ width = 32, height = 32 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 109 96'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M107.53 75.6899L89.81 94.6899C89.4267 95.1026 88.9626 95.432 88.4464 95.6573C87.9303 95.8827 87.3732 95.9994 86.81 95.9999H2.81C2.40937 95.9997 2.01749 95.8827 1.68235 95.6631C1.34722 95.4436 1.08338 95.1311 0.923128 94.7639C0.762878 94.3967 0.713178 93.9908 0.780118 93.5958C0.847058 93.2008 1.02772 92.8338 1.3 92.5399L19 73.5399C19.3833 73.1272 19.8474 72.7979 20.3636 72.5725C20.8797 72.3472 21.4368 72.2305 22 72.2299H106C106.404 72.2216 106.802 72.333 107.143 72.5502C107.484 72.7674 107.754 73.0806 107.917 73.4504C108.081 73.8203 108.131 74.2303 108.062 74.6288C107.993 75.0273 107.808 75.3965 107.53 75.6899ZM89.81 37.4199C89.4253 37.0091 88.9608 36.6811 88.445 36.4558C87.9292 36.2306 87.3728 36.1129 86.81 36.11H2.81C2.40937 36.1102 2.01749 36.2272 1.68235 36.4468C1.34722 36.6663 1.08338 36.9788 0.923128 37.346C0.762878 37.7132 0.713178 38.1191 0.780118 38.5141C0.847058 38.9091 1.02772 39.2761 1.3 39.57L19 58.58C19.3847 58.9908 19.8492 59.3188 20.365 59.5441C20.8808 59.7693 21.4372 59.887 22 59.8899H106C106.4 59.8878 106.79 59.7693 107.124 59.5491C107.458 59.3288 107.72 59.0162 107.879 58.6494C108.038 58.2826 108.087 57.8774 108.019 57.4833C107.952 57.0892 107.772 56.7232 107.5 56.43L89.81 37.4199ZM2.81 23.7699H86.81C87.3732 23.7694 87.9303 23.6527 88.4464 23.4273C88.9626 23.202 89.4267 22.8726 89.81 22.4599L107.53 3.45995C107.808 3.16647 107.993 2.79726 108.062 2.39877C108.131 2.00028 108.081 1.59031 107.917 1.22045C107.754 0.850591 107.484 0.537368 107.143 0.320195C106.802 0.103021 106.404 -0.0084012 106 -5.10788e-05H22C21.4368 0.000541762 20.8797 0.117167 20.3636 0.342553C19.8474 0.567938 19.3833 0.897249 19 1.30995L1.3 20.3099C1.02772 20.6038 0.847058 20.9708 0.780118 21.3658C0.713178 21.7608 0.762878 22.1667 0.923128 22.5339C1.08338 22.9011 1.34722 23.2136 1.68235 23.4331C2.01749 23.6527 2.40937 23.7697 2.81 23.7699Z'
				fill='url(#paint0_linear_0_4)'
			/>
			<defs>
				<linearGradient
					id='paint0_linear_0_4'
					x1='9.81'
					y1='98.29'
					x2='97.89'
					y2='-1.01005'
					gradientUnits='userSpaceOnUse'>
					<stop offset='0.08' stop-color='#9945FF' />
					<stop offset='0.3' stop-color='#8752F3' />
					<stop offset='0.5' stop-color='#5497D5' />
					<stop offset='0.6' stop-color='#43B4CA' />
					<stop offset='0.72' stop-color='#28E0B9' />
					<stop offset='0.97' stop-color='#19FB9B' />
				</linearGradient>
			</defs>
		</svg>
	);
}

export default SolanaIcon;
