function BinanceIcon({ width = 32, height = 32 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clip-path='url(#clip0_1_2)'>
				<path
					d='M9.7888 13.446L16.0038 7.23355L22.2213 13.4511L25.8356 9.83429L16.0038 0L6.17203 9.83177L9.7888 13.446Z'
					fill='#F3BA2F'
				/>
				<path
					d='M0 16.0013L3.61425 12.3845L7.23102 16.0013L3.61425 19.6155L0 16.0013Z'
					fill='#F3BA2F'
				/>
				<path
					d='M9.7888 18.554L16.0038 24.769L22.2213 18.5515L25.8381 22.1632L16.0063 31.9975L6.17203 22.1708L9.7888 18.554Z'
					fill='#F3BA2F'
				/>
				<path
					d='M24.769 16.0013L28.3832 12.3845L32 15.9987L28.3832 19.618L24.769 16.0013Z'
					fill='#F3BA2F'
				/>
				<path
					d='M19.6711 15.9987L16.0038 12.3289L13.2918 15.0408L12.9784 15.3517L12.3365 15.9937L16.0038 19.6585L19.6711 16.0013V15.9987Z'
					fill='#F3BA2F'
				/>
				width={width}
				height={height}
			</g>
			<defs>
				<clipPath id='clip0_1_2'>
					<rect width={width} height={height} fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}

export default BinanceIcon;
