import {
	AptosIcon,
	BinanceIcon,
	BitcoinIcon,
	CardanoIcon,
	EthereumIcon,
	LitecoinIcon,
	MaticIcon,
	MoneroIcon,
	PolkadotIcon,
	RippleIcon,
	SolanaIcon,
	StellarIcon,
	TronIcon,
} from "assets";

const WALLET_ADDRESSES = {
	BTC: {
		name: "Bitcoin",
		currencies: [{ id: 1, symbol: "BTC", icon: <BitcoinIcon /> }],
		address: "1DJTHLzimpybbHjRnyxCpibiA2SXp1byp1",
		tag: null,
		chainId: "",
		icon: <BitcoinIcon />,
		icon2x: <BitcoinIcon width={64} height={64} />,
	},
	BNB: {
		name: "Binance",
		currencies: [{ id: 2, symbol: "BNB", icon: <BinanceIcon /> }],
		address: "0x4ac5eece76b1c8f2a9880decea73596a8a9f7a7a",
		tag: null,
		chainId: "0x38",
		icon: <BinanceIcon />,
		icon2x: <BinanceIcon width={64} height={64} />,
	},
	LTC: {
		name: "Litecoin",
		currencies: [{ id: 3, symbol: "LTC", icon: <LitecoinIcon /> }],
		address: "Lgzj2RpZWtiC1R4vyGqeECHrsuJtZyto65",
		tag: null,
		chainId: "",
		icon: <LitecoinIcon />,
		icon2x: <LitecoinIcon width={64} height={64} />,
	},
	TRX: {
		name: "Tron",
		currencies: [{ id: 4, symbol: "TRX", icon: <TronIcon /> }],
		address: "TDDiVnYvz2vMQVzYiUUvTc8XoUxs16tzwb",
		tag: null,
		chainId: "",
		icon: <TronIcon />,
		icon2x: <TronIcon width={64} height={64} />,
	},
	DOT: {
		name: "Polkadot",
		currencies: [{ id: 5, symbol: "DOT", icon: <PolkadotIcon /> }],
		address: "15nFb3u9zr7CFGd6E3KYXCiRhXJ5fgbR9ZjqzVaqaMHPUeo6",
		tag: null,
		chainId: "",
		icon: <PolkadotIcon />,
		icon2x: <PolkadotIcon width={64} height={64} />,
	},
	MATIC: {
		name: "Polygon",
		currencies: [{ id: 6, symbol: "MATIC", icon: <MaticIcon /> }],
		address: "0x4ac5eece76b1c8f2a9880decea73596a8a9f7a7a",
		tag: null,
		chainId: "0x89",
		icon: <MaticIcon />,
		icon2x: <MaticIcon width={64} height={64} />,
	},
	SOL: {
		name: "Solana",
		currencies: [{ id: 7, symbol: "SOL", icon: <SolanaIcon /> }],
		address: "89HtWrpzs6jNWcvzPMGhvxZR4bsv9PhGgZcGdSXawNLG",
		tag: null,
		chainId: "",
		icon: <SolanaIcon />,
		icon2x: <SolanaIcon width={64} height={64} />,
	},
	APT: {
		name: "Aptos",
		currencies: [{ id: 8, symbol: "APT", icon: <AptosIcon /> }],
		address:
			"0x3878d5b4604666322bdd8ee307bc5b9a2f6fb9cd7c462f30c55079e2df73c1e4",
		tag: null,
		chainId: "",
		icon: <AptosIcon />,
		icon2x: <AptosIcon width={64} height={64} />,
	},
	ADA: {
		name: "Cardano",
		currencies: [{ id: 9, symbol: "ADA", icon: <CardanoIcon /> }],
		address:
			"DdzFFzCqrhssQW1qiaxiLzGJsLsBUa7jmmAhfTv8FM9q91NRnyqiCg21X8JCVEXHYGuEm6XicwEvijjSpKEYFJntTAuvitkMA8oa1sKc",
		tag: null,
		chainId: "",
		icon: <CardanoIcon />,
		icon2x: <CardanoIcon width={64} height={64} />,
	},
	ETH: {
		name: "Ethereum",
		currencies: [{ id: 10, symbol: "ETH", icon: <EthereumIcon /> }],
		address: "0x4ac5eece76b1c8f2a9880decea73596a8a9f7a7a",
		tag: null,
		chainId: "0x1",
		icon: <EthereumIcon />,
		icon2x: <EthereumIcon width={64} height={64} />,
	},
	XRP: {
		name: "Ripple",
		currencies: [{ id: 11, symbol: "XRP", icon: <RippleIcon /> }],
		address: "rEb8TK3gBgk5auZkwc6sHnwrGVJH8DuaLh",
		tag: "105479381",
		chainId: "",
		icon: <RippleIcon />,
		icon2x: <RippleIcon width={64} height={64} />,
	},
	XLM: {
		name: "Stellar",
		currencies: [{ id: 12, symbol: "XLM", icon: <StellarIcon /> }],
		address: "GAHK7EEG2WWHVKDNT4CEQFZGKF2LGDSW2IVM4S5DP42RBW3K6BTODB4A",
		tag: "1036783474", //"tag": "1036783474",
		chainId: "",
		icon: <StellarIcon />,
		icon2x: <StellarIcon width={64} height={64} />,
	},
	XMR: {
		name: "Monero",
		currencies: [{ id: 1, symbol: "XMR", icon: <MoneroIcon /> }],
		address:
			"8AzYoqNBMSg2JMRuh83iJmZ3Q9PcLcNVfGJatwn2qPT66Kxjz4NmcW6781AAeQdZ49L4A1ZonNCJGLShoqnD9ybm6Do7N4w",
		tag: null,
		chainId: "",
		icon: <MoneroIcon />,
		icon2x: <MoneroIcon width={64} height={64} />,
	},
};

export default WALLET_ADDRESSES;
