function CardanoIcon({ width = 32, height = 32 }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 32 32'>
			<g id='ada' transform='translate(-16030.339 -7030)'>
				<rect
					id='Rectangle_3313'
					data-name='Rectangle 3313'
					width={width}
					height={height}
					transform='translate(16030.339 7030)'
					fill='none'
				/>
				<g id='Layer_2_1_' transform='translate(16035.517 7036)'>
					<g id='Layer_1-2'>
						<g
							id='Path_44388'
							data-name='Path 44388'
							transform='translate(-96.827 -139.551)'
							fill='#0033ad'>
							<path
								d='M 104.2205200195312 151.0639953613281 L 104.1339645385742 151.0639953613281 C 103.7294540405273 151.0407867431641 103.3603439331055 150.8630065917969 103.0917510986328 150.5632781982422 C 102.8227615356445 150.2631072998047 102.6873321533203 149.8769378662109 102.7104110717773 149.4759216308594 C 102.7521896362305 148.6761169433594 103.4129791259766 148.0493927001953 104.2147521972656 148.0493927001953 L 104.2254104614258 148.0493469238281 C 105.0522613525391 148.0493469238281 105.7249603271484 148.7242431640625 105.7249603271484 149.5538024902344 C 105.7278289794922 149.9561767578125 105.5735931396484 150.3348999023438 105.2906494140625 150.6198577880859 C 105.0062789916992 150.9062652587891 104.6262435913086 151.0639953613281 104.2205200195312 151.0639953613281 Z'
								stroke='none'
							/>
							<path
								d='M 104.2254104614258 148.0993499755859 C 104.221809387207 148.0993499755859 104.2183532714844 148.099365234375 104.2147521972656 148.0993957519531 C 103.4413681030273 148.0993957519531 102.8007278442383 148.7053833007812 102.7603302001953 149.4787902832031 C 102.7141647338867 150.2810211181641 103.3317108154297 150.9678192138672 104.1339645385742 151.0139923095703 C 104.1628112792969 151.0139923095703 104.1916580200195 151.0139923095703 104.2205200195312 151.0139923095703 C 105.0285339355469 151.0139923095703 105.6807174682617 150.3618316650391 105.6749649047852 149.5538024902344 C 105.6749649047852 148.7494049072266 105.0284271240234 148.0994262695312 104.2254104614258 148.0993499755859 M 104.2254104614258 147.9993591308594 L 104.2254180908203 147.9993591308594 C 105.0798416137695 147.9994201660156 105.7749633789062 148.6967468261719 105.7749633789062 149.5538024902344 C 105.7779235839844 149.96923828125 105.6185302734375 150.360595703125 105.3261337280273 150.6550903320312 C 105.0323104858398 150.9510192871094 104.6396636962891 151.1139984130859 104.2205200195312 151.1139984130859 L 104.1282119750977 151.1138153076172 C 103.7132873535156 151.0899505615234 103.3319778442383 150.9062805175781 103.0545120239258 150.5966491699219 C 102.7765808105469 150.2864990234375 102.6366424560547 149.8874664306641 102.6604995727539 149.4730377197266 C 102.7036514282227 148.6469268798828 103.3863677978516 147.9993896484375 104.2147521972656 147.9993896484375 L 104.2254104614258 147.9993591308594 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<path
							id='Path_44389'
							data-name='Path 44389'
							d='M.488,165.483a.471.471,0,1,0,.444.5.468.468,0,0,0-.444-.5Z'
							transform='translate(0.008 -155.93)'
							fill='#0033ad'
							stroke='#fff'
							stroke-width='0.1'
						/>
						<path
							id='Path_44390'
							data-name='Path 44390'
							d='M90.024,10.831a.472.472,0,0,0-.427-.843.472.472,0,0,0,.427.843Z'
							transform='translate(-84.182 -9.364)'
							fill='#0033ad'
							stroke='#fff'
							stroke-width='0.1'
						/>
						<g
							id='Path_44391'
							data-name='Path 44391'
							transform='translate(-102.303 -43.598)'
							fill='#0033ad'>
							<path
								d='M 109.297004699707 47.77168655395508 C 108.9999465942383 47.77166748046875 108.7334823608398 47.60780715942383 108.6016006469727 47.34404754638672 C 108.4097900390625 46.96040725708008 108.5648040771484 46.49275970458984 108.9471664428711 46.30157852172852 C 109.0560760498047 46.24712753295898 109.1728515625 46.21951675415039 109.2942428588867 46.21951675415039 C 109.5912933349609 46.21951675415039 109.8577575683594 46.38337707519531 109.9896469116211 46.64714813232422 C 110.1814575195312 47.03078842163086 110.0264358520508 47.49843597412109 109.6440811157227 47.68961715698242 C 109.53515625 47.74407958984375 109.4183807373047 47.77168655395508 109.297004699707 47.77168655395508 Z'
								stroke='none'
							/>
							<path
								d='M 109.297004699707 47.72168350219727 C 109.4065017700195 47.72168731689453 109.5175170898438 47.69699478149414 109.6217269897461 47.6448974609375 C 109.9795532226562 47.46597671508789 110.1238327026367 47.02734756469727 109.9449234008789 46.66950607299805 C 109.765998840332 46.31167221069336 109.3273696899414 46.16739273071289 108.9695205688477 46.34629821777344 C 108.6116943359375 46.52521896362305 108.467414855957 46.96385955810547 108.6463241577148 47.32168960571289 C 108.7731399536133 47.57532501220703 109.0304794311523 47.72167205810547 109.297004699707 47.72168350219727 M 109.297004699707 47.82168960571289 L 109.2969970703125 47.82168960571289 C 108.9808731079102 47.8216667175293 108.6972808837891 47.64720916748047 108.556884765625 47.36640930175781 C 108.3527374267578 46.95810699462891 108.5177841186523 46.46036911010742 108.9248046875 46.25685882568359 C 109.0407257080078 46.19889831542969 109.1650161743164 46.16951751708984 109.2942428588867 46.16951751708984 C 109.6103515625 46.16951751708984 109.8939514160156 46.34396743774414 110.0343627929688 46.62478637695312 C 110.2385025024414 47.03308868408203 110.0734558105469 47.53082656860352 109.6664428710938 47.73433685302734 C 109.5505142211914 47.79229736328125 109.4262161254883 47.82168960571289 109.297004699707 47.82168960571289 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44392'
							data-name='Path 44392'
							transform='translate(-33.805 -77.003)'
							fill='#0033ad'>
							<path
								d='M 36.47647476196289 82.96843719482422 C 36.35065460205078 82.96843719482422 36.22796630859375 82.93214416503906 36.12165451049805 82.86348724365234 C 35.97545623779297 82.76906585693359 35.87534713745117 82.62265014648438 35.83975601196289 82.45121765136719 C 35.80432510375977 82.28050994873047 35.83780670166016 82.10699462890625 35.93404769897461 81.962646484375 C 36.05608749389648 81.77958679199219 36.25983810424805 81.67029571533203 36.47906494140625 81.67029571533203 C 36.60487747192383 81.67029571533203 36.72756576538086 81.70658874511719 36.83386611938477 81.77523803710938 C 37.13071823120117 81.97312927246094 37.21478652954102 82.37678527832031 37.02188491821289 82.67546844482422 C 36.89944458007812 82.85913848876953 36.69569778442383 82.96843719482422 36.47647476196289 82.96843719482422 Z'
								stroke='none'
							/>
							<path
								d='M 36.47647094726562 82.91843414306641 C 36.67145156860352 82.91843414306641 36.86278533935547 82.82398986816406 36.97988510131836 82.64833831787109 C 37.15880584716797 82.37130737304688 37.08377456665039 82.00193023681641 36.80673599243164 81.81723785400391 C 36.52970504760742 81.63831329345703 36.16033935546875 81.71334838867188 35.97564697265625 81.99038696289062 C 35.79094696044922 82.26742553710938 35.87175750732422 82.64256286621094 36.14878463745117 82.82148742675781 C 36.25017547607422 82.886962890625 36.36392974853516 82.91843414306641 36.47647094726562 82.91843414306641 M 36.47647476196289 83.01844024658203 C 36.34101486206055 83.01844024658203 36.20894622802734 82.97937774658203 36.09453582763672 82.90548706054688 C 35.93699645996094 82.80374908447266 35.82912826538086 82.64602661132812 35.79080581665039 82.46138000488281 C 35.75262451171875 82.27745819091797 35.78872680664062 82.09048461914062 35.8924446105957 81.93491363525391 C 36.02378463745117 81.73790740966797 36.24307632446289 81.62029266357422 36.47906494140625 81.62029266357422 C 36.61450576782227 81.62029266357422 36.74657821655273 81.65934753417969 36.86098480224609 81.73322296142578 C 37.18105697631836 81.94659423828125 37.27153778076172 82.38108825683594 37.06388473510742 82.70258331298828 C 36.93174743652344 82.90081787109375 36.71245574951172 83.01844024658203 36.47647476196289 83.01844024658203 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44393'
							data-name='Path 44393'
							transform='translate(-40.121 -151.687)'
							fill='#0033ad'>
							<path
								d='M 43.30962371826172 162.4856567382812 C 43.29449462890625 162.4856567382812 43.27925491333008 162.4852294921875 43.26395416259766 162.4843597412109 C 42.83485412597656 162.4598236083984 42.50563430786133 162.091796875 42.53007507324219 161.6639556884766 C 42.55363464355469 161.251708984375 42.89393615722656 160.9287872314453 43.30479431152344 160.9287872314453 C 43.31992340087891 160.9287872314453 43.33516693115234 160.9292297363281 43.3504638671875 160.9300994873047 C 43.55873489379883 160.9420013427734 43.74981689453125 161.0342407226562 43.88850402832031 161.1898651123047 C 44.02675628662109 161.3450012207031 44.09629440307617 161.5440979003906 44.08433532714844 161.75048828125 C 44.06078338623047 162.1627197265625 43.72048568725586 162.4856567382812 43.30962371826172 162.4856567382812 Z'
								stroke='none'
							/>
							<path
								d='M 43.30479431152344 160.9787902832031 C 42.91979598999023 160.9787750244141 42.60226440429688 161.2771606445312 42.57999420166016 161.6668090820312 C 42.55691528320312 162.0708312988281 42.8628044128418 162.4113464355469 43.26680374145508 162.4344329833984 C 43.67097854614258 162.4577026367188 44.01133346557617 162.1516265869141 44.03441619873047 161.7476043701172 C 44.0575065612793 161.3493804931641 43.75161361694336 161.0030975341797 43.34761428833008 160.9800109863281 C 43.3332633972168 160.9791870117188 43.31897735595703 160.9787902832031 43.30479431152344 160.9787902832031 M 43.30479431152344 160.8787841796875 C 43.32086563110352 160.8787841796875 43.33705520629883 160.8792419433594 43.35331344604492 160.8801727294922 C 43.57493591308594 160.8928375244141 43.77825546264648 160.9909973144531 43.92582702636719 161.1566009521484 C 44.07297515869141 161.3217010498047 44.1469841003418 161.5336608886719 44.13424682617188 161.7533874511719 C 44.1082763671875 162.2081298828125 43.71998596191406 162.560302734375 43.26110458374023 162.5342712402344 C 42.80447387695312 162.5081787109375 42.45414352416992 162.1164703369141 42.48015594482422 161.6611022949219 C 42.50523376464844 161.222412109375 42.86745452880859 160.8787841796875 43.30479431152344 160.8787841796875 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44394'
							data-name='Path 44394'
							transform='translate(-34.188 -230.465)'
							fill='#0033ad'>
							<path
								d='M 36.88379669189453 245.8348083496094 C 36.88376998901367 245.8348083496094 36.88375473022461 245.8348083496094 36.88373184204102 245.8348083496094 C 36.63742065429688 245.8348083496094 36.41461181640625 245.6979064941406 36.30226135253906 245.4775390625 C 36.14205932617188 245.1571502685547 36.27104187011719 244.7646636962891 36.58959197998047 244.6022796630859 C 36.68064880371094 244.5558471679688 36.77862930297852 244.5323181152344 36.88082122802734 244.5323181152344 C 37.12871170043945 244.5323181152344 37.35250091552734 244.6692199707031 37.46485900878906 244.8896179199219 C 37.62504959106445 245.2099914550781 37.49607849121094 245.6024627685547 37.17752838134766 245.7648468017578 C 37.08457183837891 245.8113250732422 36.98585891723633 245.8348083496094 36.88379669189453 245.8348083496094 Z'
								stroke='none'
							/>
							<path
								d='M 36.88082504272461 244.5823211669922 C 36.79008865356445 244.5823059082031 36.69826126098633 244.6029968261719 36.61230087280273 244.6468200683594 C 36.31795120239258 244.796875 36.19675064086914 245.1547241210938 36.34680938720703 245.454833984375 C 36.49687194824219 245.7491760253906 36.85470199584961 245.8703765869141 37.15481948852539 245.7203063964844 C 37.44916915893555 245.5702514648438 37.57036972045898 245.2124328613281 37.42031097412109 244.9123229980469 C 37.3140754699707 244.7039489746094 37.10076522827148 244.5823364257812 36.88082504272461 244.5823211669922 M 36.88082122802734 244.4823150634766 L 36.88082885742188 244.4823150634766 C 37.14762115478516 244.4823303222656 37.38848114013672 244.6296997070312 37.5093994140625 244.8668975830078 C 37.68201065063477 245.2120971679688 37.54315948486328 245.6345825195312 37.20024108886719 245.8093872070312 C 37.09992980957031 245.8595581054688 36.99367904663086 245.8848114013672 36.88373947143555 245.8848114013672 C 36.6185188293457 245.8848114013672 36.37863922119141 245.7374572753906 36.25772094726562 245.5002593994141 C 36.08510971069336 245.1550445556641 36.22396087646484 244.7325439453125 36.56687927246094 244.5577392578125 C 36.66503143310547 244.5076904296875 36.77064895629883 244.4823150634766 36.88082122802734 244.4823150634766 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44395'
							data-name='Path 44395'
							transform='translate(-79.399 -101.448)'
							fill='#0033ad'>
							<path
								d='M 85.12202453613281 109.4250259399414 C 85.12200927734375 109.4250259399414 85.12201690673828 109.4250259399414 85.12199401855469 109.4250259399414 C 84.94538879394531 109.4250259399414 84.77313232421875 109.3735656738281 84.62387847900391 109.2762222290039 C 84.41980743408203 109.1431427001953 84.28032684326172 108.9389572143555 84.23111724853516 108.7013092041016 C 84.18220520019531 108.4650573730469 84.22843170166016 108.2243804931641 84.36129760742188 108.0235900878906 C 84.52535247802734 107.7663192749023 84.80741882324219 107.6123123168945 85.11534881591797 107.6123123168945 C 85.29195404052734 107.6123123168945 85.46420288085938 107.6637725830078 85.61347198486328 107.761116027832 C 85.81493377685547 107.8895797729492 85.95401763916016 108.0896606445312 86.00465393066406 108.3242263793945 C 86.05614471435547 108.5627517700195 86.01054382324219 108.8075256347656 85.87623596191406 109.0134658813477 C 85.71200561523438 109.2710113525391 85.42994689941406 109.4250259399414 85.12202453613281 109.4250259399414 Z'
								stroke='none'
							/>
							<path
								d='M 85.12200164794922 109.3750305175781 C 85.40119171142578 109.3750381469727 85.67340087890625 109.2385559082031 85.83435821533203 108.9861602783203 C 86.09405517578125 108.5879287719727 85.98440551757812 108.0569458007812 85.58616638183594 107.8030014038086 C 85.18791961669922 107.5432739257812 84.65695190429688 107.6529541015625 84.40299224853516 108.0511779785156 C 84.14329528808594 108.4436492919922 84.25294494628906 108.9746170043945 84.65118408203125 109.2343368530273 C 84.79701995849609 109.3294525146484 84.96068572998047 109.3750228881836 85.12200164794922 109.3750305175781 M 85.12203216552734 109.4750289916992 C 84.93567657470703 109.4750289916992 84.75396728515625 109.4207611083984 84.5965576171875 109.3180999755859 C 84.38125610351562 109.1776885986328 84.23408508300781 108.9622421264648 84.18215179443359 108.7114486694336 C 84.13051605224609 108.4620590209961 84.17933654785156 108.2079772949219 84.31959533691406 107.9960021972656 C 84.492431640625 107.7249603271484 84.79024505615234 107.5623092651367 85.11534881591797 107.5623092651367 C 85.30167388916016 107.5623092651367 85.48336791992188 107.616569519043 85.64079284667969 107.71923828125 C 85.85316467285156 107.8546600341797 86.00004577636719 108.0659561157227 86.05352783203125 108.3136672973633 C 86.10785675048828 108.5653381347656 86.05977630615234 108.8235702514648 85.91811370849609 109.0407791137695 C 85.74492645263672 109.3123779296875 85.44711303710938 109.4750289916992 85.12203216552734 109.4750289916992 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44396'
							data-name='Path 44396'
							transform='translate(-226.873 -43.287)'
							fill='#0033ad'>
							<path
								d='M 241.4994659423828 47.44455337524414 C 241.4994506835938 47.44455337524414 241.4994506835938 47.44455337524414 241.4994354248047 47.44455337524414 C 241.3470153808594 47.44454574584961 241.1993560791016 47.40058517456055 241.0724182128906 47.31741333007812 C 240.8978118896484 47.2049560546875 240.7780609130859 47.0316047668457 240.7355651855469 46.82952499389648 C 240.6925811767578 46.62507629394531 240.7333526611328 46.41499328613281 240.8503570556641 46.23800277709961 C 240.9935455322266 46.01943588256836 241.2373657226562 45.88884353637695 241.5024566650391 45.88884353637695 C 241.6548614501953 45.88884353637695 241.8025207519531 45.93280410766602 241.9294738769531 46.01598358154297 C 242.2879791259766 46.2508659362793 242.3876647949219 46.73500442504883 242.1516876220703 47.09521484375 C 242.0083618164062 47.31396102905273 241.7645263671875 47.44455337524414 241.4994659423828 47.44455337524414 Z'
								stroke='none'
							/>
							<path
								d='M 241.4994506835938 47.39455032348633 C 241.7372436523438 47.39456558227539 241.9709777832031 47.27979278564453 242.10986328125 47.06781387329102 C 242.3291625976562 46.73307418823242 242.23681640625 46.27711486816406 241.9020690917969 46.05780410766602 C 241.5673370361328 45.83848190307617 241.1113739013672 45.93084335327148 240.8920593261719 46.26557540893555 C 240.6669616699219 46.60609436035156 240.7593078613281 47.0562744140625 241.0998229980469 47.27559280395508 C 241.2225952148438 47.35602951049805 241.3617095947266 47.3945426940918 241.4994506835938 47.39455032348633 M 241.4994659423828 47.49455642700195 L 241.4994659423828 47.49455642700195 C 241.3372650146484 47.49454498291016 241.1801147460938 47.44775390625 241.0450286865234 47.35924530029297 C 240.8594055175781 47.23969650268555 240.7319030761719 47.05507278442383 240.6866455078125 46.83981323242188 C 240.6408843994141 46.62218475341797 240.6842193603516 46.39866638183594 240.8086395263672 46.21042633056641 C 240.9609985351562 45.97788619995117 241.2204437255859 45.83884429931641 241.5024566650391 45.83884429931641 C 241.6646270751953 45.83884429931641 241.82177734375 45.88563537597656 241.9568786621094 45.97416305541992 C 242.3384552001953 46.22415542602539 242.4445953369141 46.73934555053711 242.1935119628906 47.12261581420898 C 242.0409088134766 47.35551452636719 241.7814636230469 47.49455642700195 241.4994659423828 47.49455642700195 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<path
							id='Path_44397'
							data-name='Path 44397'
							d='M268.839,10.421a.471.471,0,1,0-.138-.658.486.486,0,0,0,.138.658Z'
							transform='translate(-253.122 -9.001)'
							fill='#0033ad'
							stroke='#fff'
							stroke-width='0.1'
						/>
						<g
							id='Path_44398'
							data-name='Path 44398'
							transform='translate(-208.959 -139.323)'
							fill='#0033ad'>
							<path
								d='M 223.2147369384766 150.8191833496094 L 223.1339416503906 150.8191833496094 C 222.7314147949219 150.7989654541016 222.3629302978516 150.6227569580078 222.0938262939453 150.3229675292969 C 221.8233947753906 150.0216674804688 221.6872253417969 149.6338958740234 221.7104034423828 149.2310791015625 C 221.7505798339844 148.4331970214844 222.4098205566406 147.8079071044922 223.2111968994141 147.8079071044922 C 223.2400970458984 147.8079071044922 223.2692108154297 147.8087310791016 223.2984161376953 147.8104095458984 C 224.1009826660156 147.8523254394531 224.7251739501953 148.5107421875 224.7191619873047 149.3093566894531 C 224.7191619873047 150.1417083740234 224.0442810058594 150.8191833496094 223.2147369384766 150.8191833496094 Z'
								stroke='none'
							/>
							<path
								d='M 223.2111968994141 147.8578948974609 C 222.4452972412109 147.8578796386719 221.7993011474609 148.4599609375 221.7603149414062 149.2339477539062 C 221.7141418457031 150.0361938476562 222.3316955566406 150.7287750244141 223.1339416503906 150.7691802978516 C 223.1627960205078 150.7691802978516 223.1916656494141 150.7691802978516 223.2147369384766 150.7691802978516 C 224.0169830322266 150.7691802978516 224.6691589355469 150.1169891357422 224.6691589355469 149.3089752197266 C 224.6749420166016 148.5413665771484 224.0689239501953 147.9007263183594 223.2955474853516 147.8603363037109 C 223.2673034667969 147.8587036132812 223.2391510009766 147.85791015625 223.2111968994141 147.8578948974609 M 223.2111663818359 147.7579040527344 C 223.2410583496094 147.7579040527344 223.2711334228516 147.7587585449219 223.3013153076172 147.760498046875 C 224.1303863525391 147.8037872314453 224.7753753662109 148.4843139648438 224.7691650390625 149.3097229003906 C 224.7691650390625 150.1692657470703 224.0718536376953 150.8691864013672 223.2147369384766 150.8691864013672 L 223.12890625 150.8690490722656 C 222.7154998779297 150.8482360839844 222.3346862792969 150.6661529541016 222.0566253662109 150.3563690185547 C 221.7772064208984 150.0450744628906 221.6365356445312 149.6444091796875 221.6604919433594 149.2281951904297 C 221.7019958496094 148.404052734375 222.3831481933594 147.7579040527344 223.2111663818359 147.7579040527344 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44399'
							data-name='Path 44399'
							transform='translate(-124.736 -90.814)'
							fill='#0033ad'>
							<path
								d='M 133.8385162353516 99.34871673583984 C 133.2653350830078 99.34871673583984 132.7484283447266 99.03080749511719 132.4895477294922 98.51903533935547 C 132.1144561767578 97.78076171875 132.4142150878906 96.87034606933594 133.1577758789062 96.48963165283203 C 133.3702545166016 96.38168334960938 133.5979766845703 96.32699584960938 133.8347473144531 96.32699584960938 C 134.4072570800781 96.32699584960938 134.9234008789062 96.64543151855469 135.1817779541016 97.15805816650391 C 135.2872467041016 97.36901092529297 135.3429565429688 97.60426330566406 135.3429565429688 97.83851623535156 C 135.3429565429688 98.67124176025391 134.6680755615234 99.34871673583984 133.8385162353516 99.34871673583984 Z'
								stroke='none'
							/>
							<path
								d='M 133.8385162353516 99.29871368408203 C 134.6407623291016 99.29871368408203 135.2929534912109 98.64652252197266 135.2929534912109 97.83851623535156 C 135.2929534912109 97.61342620849609 135.2409973144531 97.38833618164062 135.1371154785156 97.18055725097656 C 134.7735137939453 96.45913696289062 133.8962554931641 96.17055511474609 133.1805725097656 96.53414154052734 C 132.4591217041016 96.90352630615234 132.1705627441406 97.78080749511719 132.5341644287109 98.49646759033203 C 132.7823333740234 98.98704528808594 133.2844543457031 99.29871368408203 133.8385162353516 99.29871368408203 M 133.8385162353516 99.39871215820312 C 133.2463684082031 99.39871215820312 132.7123718261719 99.07028198242188 132.4449310302734 98.54160308837891 C 132.0574340820312 97.77890777587891 132.3669586181641 96.83836364746094 133.1349945068359 96.44513702392578 C 133.3546905517578 96.33351135253906 133.5900268554688 96.27699279785156 133.8347473144531 96.27699279785156 C 134.4262542724609 96.27699279785156 134.9595031738281 96.60597229003906 135.2264251708984 97.13554382324219 C 135.3354034423828 97.35355377197266 135.3929443359375 97.59654235839844 135.3929443359375 97.83851623535156 C 135.3929443359375 98.69881439208984 134.6956329345703 99.39871215820312 133.8385162353516 99.39871215820312 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44400'
							data-name='Path 44400'
							transform='translate(-299.482 -76.476)'
							fill='#0033ad'>
							<path
								d='M 318.4307861328125 82.40933990478516 C 318.4307861328125 82.40933990478516 318.4307556152344 82.40933990478516 318.4307250976562 82.40933990478516 C 318.1852722167969 82.40933227539062 317.9604187011719 82.27268981933594 317.8439636230469 82.05274963378906 C 317.6870727539062 81.73277282714844 317.8161315917969 81.34303283691406 318.1309509277344 81.18254852294922 C 318.2222900390625 81.13599395751953 318.3245849609375 81.11138153076172 318.4268188476562 81.11138153076172 C 318.6733093261719 81.11138153076172 318.8952941894531 81.24654388427734 319.0062255859375 81.464111328125 C 319.1694641113281 81.78434753417969 319.0431823730469 82.177001953125 318.7246704101562 82.33937835693359 C 318.6317138671875 82.38585662841797 318.532958984375 82.40933990478516 318.4307861328125 82.40933990478516 Z'
								stroke='none'
							/>
							<path
								d='M 318.4307556152344 82.35934448242188 C 318.5217590332031 82.35935211181641 318.6143188476562 82.33866119384766 318.7019653320312 82.29483795166016 C 318.9963073730469 82.14476776123047 319.1117248535156 81.78117370605469 318.961669921875 81.48682403564453 C 318.8116149902344 81.19248199462891 318.4479675292969 81.07701873779297 318.1536560058594 81.22710418701172 C 317.8592834472656 81.37715911865234 317.7438659667969 81.73500061035156 317.8881530761719 82.02935028076172 C 317.9984741210938 82.23773193359375 318.2100830078125 82.35932922363281 318.4307556152344 82.35934448242188 M 318.4308166503906 82.45934295654297 L 318.4308166503906 82.45934295654297 C 318.1667175292969 82.45932769775391 317.9249267578125 82.3125 317.7997741699219 82.07614135742188 C 317.6302490234375 81.73040771484375 317.7692565917969 81.31081390380859 318.1082458496094 81.13800811767578 C 318.2065734863281 81.08788299560547 318.3167419433594 81.06137847900391 318.4268188476562 81.06137847900391 C 318.6921997070312 81.06137847900391 318.9312438964844 81.20700073242188 319.0507507324219 81.44139862060547 C 319.2265319824219 81.78620147705078 319.0904541015625 82.20902252197266 318.7473754882812 82.38393402099609 C 318.6470642089844 82.43408966064453 318.540771484375 82.45934295654297 318.4308166503906 82.45934295654297 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44401'
							data-name='Path 44401'
							transform='translate(-245.76 -101.066)'
							fill='#0033ad'>
							<path
								d='M 261.6709594726562 109.0168533325195 C 261.3269348144531 109.0168533325195 261.0161437988281 108.8253021240234 260.85986328125 108.5169296264648 C 260.7506713867188 108.3015289306641 260.7327270507812 108.0562744140625 260.8093872070312 107.8263397216797 C 260.8857727050781 107.5971603393555 261.0467834472656 107.4106597900391 261.2627563476562 107.3012008666992 C 261.3900451660156 107.2384338378906 261.5256652832031 107.2067337036133 261.6663818359375 107.2067337036133 C 262.0118103027344 107.2067337036133 262.322998046875 107.3973083496094 262.4784851074219 107.7041015625 C 262.7033996582031 108.1478576660156 262.5226440429688 108.6932373046875 262.0755920410156 108.9198532104492 C 261.9485778808594 108.9842224121094 261.8124389648438 109.0168533325195 261.6709594726562 109.0168533325195 Z'
								stroke='none'
							/>
							<path
								d='M 261.6663818359375 107.2567291259766 C 261.5379943847656 107.256721496582 261.4078063964844 107.2854232788086 261.2853698730469 107.3458023071289 C 260.864013671875 107.5593490600586 260.6908874511719 108.0730133056641 260.9044494628906 108.4943313598633 C 261.1179809570312 108.9156341552734 261.6315612792969 109.0887985229492 262.052978515625 108.8752517700195 C 262.4742736816406 108.6617126464844 262.6474304199219 108.1480407714844 262.4338989257812 107.7267074584961 C 262.2824096679688 107.4278259277344 261.9798278808594 107.2567443847656 261.6663818359375 107.2567291259766 M 261.6663208007812 107.1567306518555 L 261.6663208007812 107.1567306518555 C 262.0307312011719 107.15673828125 262.3590393066406 107.3578186035156 262.5231018066406 107.6815032958984 C 262.7604675292969 108.1498489379883 262.56982421875 108.7253799438477 262.0981750488281 108.9644546508789 C 261.9641418457031 109.0324020385742 261.8203735351562 109.0668487548828 261.6709594726562 109.0668487548828 C 261.3079833984375 109.0668487548828 260.9801025390625 108.8647994995117 260.8152465820312 108.5395431518555 C 260.6999816894531 108.3121337890625 260.6810607910156 108.0532302856445 260.761962890625 107.8105316162109 C 260.842529296875 107.5687789916992 261.0123596191406 107.3720474243164 261.2401733398438 107.2565994262695 C 261.3748474121094 107.1901702880859 261.5179138183594 107.1567306518555 261.6663208007812 107.1567306518555 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44402'
							data-name='Path 44402'
							transform='translate(-166.488 0.015)'
							fill='#0033ad'>
							<path
								d='M 177.2837524414062 1.237373232841492 C 177.2837066650391 1.237373232841492 177.2837219238281 1.237373232841492 177.2836761474609 1.237373232841492 C 177.2723083496094 1.237373232841492 177.2608184814453 1.237073183059692 177.2493286132812 1.236473202705383 C 176.8925018310547 1.211433291435242 176.6179504394531 0.900513231754303 176.6364288330078 0.5433232188224792 C 176.6603698730469 0.2019232362508774 176.9469299316406 -0.06478676944971085 177.2888336181641 -0.06478676944971085 C 177.3004455566406 -0.06478676944971085 177.3121185302734 -0.06448676437139511 177.3238525390625 -0.06386676430702209 C 177.6870269775391 -0.03838676586747169 177.9615478515625 0.2703632414340973 177.9367065429688 0.6244332194328308 C 177.9125823974609 0.9681312441825867 177.6257629394531 1.237373232841492 177.2837524414062 1.237373232841492 Z'
								stroke='none'
							/>
							<path
								d='M 177.2836761474609 1.187378168106079 C 177.5989532470703 1.187408089637756 177.8644714355469 0.9393113255500793 177.8868255615234 0.6209332346916199 C 177.909912109375 0.2919532358646393 177.6501922607422 0.009153233841061592 177.3212280273438 -0.01393676549196243 C 176.9923248291016 -0.03123553842306137 176.7094421386719 0.2169159501791 176.6863555908203 0.545903205871582 C 176.6690368652344 0.8806532621383667 176.9229888916016 1.163453221321106 177.2519683837891 1.186543226242065 C 177.2625732421875 1.187101125717163 177.2731781005859 1.187377214431763 177.2836761474609 1.187378168106079 M 177.2837371826172 1.287373185157776 C 177.2714233398438 1.287373185157776 177.2590484619141 1.287053227424622 177.2466888427734 1.286403179168701 C 176.8619995117188 1.25942325592041 176.5666046142578 0.9249632358551025 176.5864868164062 0.5407332181930542 C 176.6132049560547 0.1598650217056274 176.9416656494141 -0.1339417845010757 177.3264770507812 -0.1137967631220818 C 177.718017578125 -0.08633676916360855 178.0133666992188 0.2463632375001907 177.986572265625 0.6279332637786865 C 177.9606170654297 0.9977132081985474 177.6519012451172 1.287373185157776 177.2837371826172 1.287373185157776 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44403'
							data-name='Path 44403'
							transform='translate(-162.432 -53.212)'
							fill='#0033ad'>
							<path
								d='M 173.2433929443359 58.23376083374023 C 173.2433624267578 58.23376083374023 173.2433929443359 58.23376083374023 173.2433624267578 58.23376083374023 C 173.2249298095703 58.2337532043457 173.2063446044922 58.23319244384766 173.1877136230469 58.23206329345703 C 172.9464874267578 58.22029113769531 172.7245330810547 58.11447143554688 172.5633239746094 57.93411254882812 C 172.40283203125 57.75454330444336 172.3209533691406 57.52201080322266 172.3327941894531 57.27935409545898 C 172.3562469482422 56.79869079589844 172.7505645751953 56.42217254638672 173.23046875 56.42217254638672 C 173.2488861083984 56.42217254638672 173.2674713134766 56.4227409362793 173.2861175537109 56.42387390136719 C 173.5283813476562 56.43569183349609 173.7513427734375 56.54178237915039 173.913330078125 56.72257232666016 C 174.0748901367188 56.90289306640625 174.1557464599609 57.13537216186523 174.1410217285156 57.37718200683594 C 174.1175842285156 57.85724258422852 173.7232666015625 58.23376083374023 173.2433929443359 58.23376083374023 Z'
								stroke='none'
							/>
							<path
								d='M 173.2433624267578 58.18375778198242 C 173.6936187744141 58.18378448486328 174.0688629150391 57.82978057861328 174.0911102294922 57.37414169311523 C 174.1199493408203 56.90088272094727 173.75634765625 56.49687194824219 173.2830810546875 56.47378158569336 C 172.8095703125 56.44502258300781 172.4058227539062 56.80853652954102 172.3827362060547 57.28179168701172 C 172.3596496582031 57.75506210327148 172.7174682617188 58.15906143188477 173.1907501220703 58.18215179443359 C 173.2083740234375 58.1832275390625 173.2259368896484 58.18375778198242 173.2433624267578 58.18375778198242 M 173.2433929443359 58.28376388549805 L 173.2433319091797 58.28376388549805 C 173.2238922119141 58.28375244140625 173.2043151855469 58.28316116333008 173.1846923828125 58.2819709777832 C 172.9305725097656 58.26958084106445 172.6962432861328 58.15785217285156 172.5260467529297 57.96743392944336 C 172.3567352294922 57.77800369262695 172.2703704833984 57.53278350830078 172.2828521728516 57.27692413330078 C 172.3085479736328 56.75012588500977 172.7567596435547 56.34171676635742 173.2891387939453 56.37396240234375 C 173.5442810058594 56.38640213012695 173.7796020507812 56.49838256835938 173.9505767822266 56.68921279907227 C 174.1211242675781 56.87955093383789 174.2064819335938 57.12496185302734 174.19091796875 57.38022232055664 C 174.1662292480469 57.8863525390625 173.75 58.28376388549805 173.2433929443359 58.28376388549805 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44404'
							data-name='Path 44404'
							transform='translate(-79.635 -197.514)'
							fill='#0033ad'>
							<path
								d='M 85.37101745605469 211.3755035400391 C 85.37098693847656 211.3755035400391 85.37097930908203 211.3755035400391 85.37094879150391 211.3755035400391 C 85.02690887451172 211.3754730224609 84.71611785888672 211.1839141845703 84.55985260009766 210.8755645751953 C 84.45066833496094 210.6601715087891 84.43275451660156 210.4149322509766 84.5093994140625 210.1849822998047 C 84.58579254150391 209.955810546875 84.74680328369141 209.7693023681641 84.96276092529297 209.6598358154297 C 85.08975219726562 209.5954742431641 85.22588348388672 209.5628509521484 85.36737060546875 209.5628509521484 C 85.71138763427734 209.5628509521484 86.02219390869141 209.75439453125 86.17848968505859 210.062744140625 C 86.28767395019531 210.2781524658203 86.30558776855469 210.5234222412109 86.22895050048828 210.7533569335938 C 86.15255737304688 210.9825439453125 85.99155426025391 211.1690368652344 85.77558135986328 211.2784881591797 C 85.64858245849609 211.3428497314453 85.51245880126953 211.3755035400391 85.37101745605469 211.3755035400391 Z'
								stroke='none'
							/>
							<path
								d='M 85.37094879150391 211.3255004882812 C 85.49968719482422 211.3255157470703 85.63021850585938 211.2961120605469 85.75298309326172 211.23388671875 C 86.17430114746094 211.0203552246094 86.34745025634766 210.5066833496094 86.13388824462891 210.0853576660156 C 85.92034149169922 209.6640625 85.40665435791016 209.4908752441406 84.98537445068359 209.7044372558594 C 84.56404113769531 209.9179992675781 84.39089965820312 210.4316711425781 84.60445404052734 210.8529663085938 C 84.75576019287109 211.1515197753906 85.05786895751953 211.3254699707031 85.37094879150391 211.3255004882812 M 85.37101745605469 211.4254913330078 L 85.37100982666016 211.4254913330078 C 85.00798034667969 211.4254608154297 84.68008422851562 211.2234039306641 84.51525115966797 210.8981781005859 C 84.39999389648438 210.6707763671875 84.38105773925781 210.4118804931641 84.46195983886719 210.1691741943359 C 84.54254150390625 209.9274291992188 84.71237182617188 209.7306976318359 84.94016265869141 209.615234375 C 85.07421112060547 209.5473022460938 85.21794891357422 209.5128479003906 85.36737060546875 209.5128479003906 C 85.73033905029297 209.5128479003906 86.0582275390625 209.7149047851562 86.22309112548828 210.0401458740234 C 86.33836364746094 210.2675628662109 86.35728454589844 210.5264739990234 86.27638244628906 210.7691650390625 C 86.19580078125 211.0109252929688 86.02597045898438 211.2076416015625 85.79819488525391 211.3230895996094 C 85.66413879394531 211.3910369873047 85.52040863037109 211.4254913330078 85.37101745605469 211.4254913330078 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44405'
							data-name='Path 44405'
							transform='translate(-180.808 -90.835)'
							fill='#0033ad'>
							<path
								d='M 193.3401794433594 99.35984802246094 C 193.3401489257812 99.35984802246094 193.3401184082031 99.35984802246094 193.340087890625 99.35984802246094 C 193.0473175048828 99.35984039306641 192.7617797851562 99.27487182617188 192.5143432617188 99.11415100097656 C 192.1769561767578 98.89498901367188 191.9460906982422 98.55754852294922 191.8642578125 98.16400909423828 C 191.7826080322266 97.77139282226562 191.858642578125 97.36891937255859 192.0783233642578 97.03073120117188 C 192.3605804443359 96.60434722900391 192.8314208984375 96.35000610351562 193.3380584716797 96.35000610351562 C 193.6338348388672 96.35000610351562 193.9187469482422 96.4346923828125 194.1620178222656 96.59487915039062 C 194.4991455078125 96.81386566162109 194.7300109863281 97.15129852294922 194.8118438720703 97.54484558105469 C 194.8934783935547 97.93746948242188 194.8174438476562 98.33993530273438 194.5977630615234 98.67813873291016 C 194.3204650878906 99.10501098632812 193.850341796875 99.35984802246094 193.3401794433594 99.35984802246094 Z'
								stroke='none'
							/>
							<path
								d='M 193.3380584716797 96.40000915527344 C 192.8474578857422 96.40000915527344 192.3915252685547 96.64818572998047 192.1202545166016 97.05796813964844 C 191.6816101074219 97.73323059082031 191.8663177490234 98.63357543945312 192.5415802001953 99.07221984863281 C 193.2168273925781 99.51084136962891 194.1171875 99.32617950439453 194.5558319091797 98.65090179443359 C 194.9944763183594 97.97563171386719 194.8097839355469 97.07527160644531 194.134521484375 96.63664245605469 C 193.8978881835938 96.48081207275391 193.6208648681641 96.40000915527344 193.3380584716797 96.40000915527344 M 193.3380584716797 96.30001068115234 C 193.6436309814453 96.30001068115234 193.9380645751953 96.38752746582031 194.1895294189453 96.55311584472656 C 194.5376586914062 96.77925872802734 194.7762298583984 97.12796783447266 194.8607940673828 97.53466796875 C 194.9451293945312 97.94029235839844 194.8666076660156 98.35604858398438 194.6396942138672 98.70536804199219 C 194.3531494140625 99.14649963378906 193.8673095703125 99.40985107421875 193.340087890625 99.40985107421875 C 193.0376434326172 99.40985107421875 192.7426910400391 99.32209777832031 192.4871063232422 99.15608215332031 C 192.1384582519531 98.92959594726562 191.8998565673828 98.58088684082031 191.8152923583984 98.17417907714844 C 191.73095703125 97.76857757568359 191.8094787597656 97.35282135009766 192.0363922119141 97.00350189208984 C 192.3281707763672 96.56272125244141 192.8146057128906 96.30001068115234 193.3380584716797 96.30001068115234 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44406'
							data-name='Path 44406'
							transform='translate(-180.991 -187.963)'
							fill='#0033ad'>
							<path
								d='M 193.5366363525391 202.4442749023438 C 193.5365905761719 202.4442749023438 193.5365447998047 202.4442749023438 193.5364990234375 202.4442749023438 C 192.9639892578125 202.4442138671875 192.4478454589844 202.1257629394531 192.1895141601562 201.6132049560547 C 192.0080108642578 201.2559509277344 191.9769744873047 200.8489227294922 192.1021575927734 200.4672393798828 C 192.2280578613281 200.0834655761719 192.4964904785156 199.7717437744141 192.8580627441406 199.5895233154297 C 193.0702514648438 199.4817047119141 193.2979888916016 199.427001953125 193.5347747802734 199.427001953125 C 194.1049346923828 199.427001953125 194.6191711425781 199.7428283691406 194.8785705566406 200.2517700195312 C 194.8849639892578 200.2592926025391 194.8894958496094 200.2672882080078 194.8915863037109 200.2778472900391 C 195.2524719238281 201.0162506103516 194.9493865966797 201.9136962890625 194.2130584716797 202.2818298339844 C 194.0010070800781 202.3895721435547 193.7733306884766 202.4442749023438 193.5366363525391 202.4442749023438 Z'
								stroke='none'
							/>
							<path
								d='M 193.5347747802734 199.4769897460938 C 193.3141174316406 199.4769744873047 193.0904541015625 199.5275268554688 192.8805694580078 199.6341705322266 C 192.1591186523438 199.9977722167969 191.8705596923828 200.8750305175781 192.2341613769531 201.5906982421875 C 192.5977630615234 202.3121337890625 193.4750366210938 202.6007080078125 194.1907043457031 202.2371063232422 C 194.9063720703125 201.8792877197266 195.2007141113281 201.0135498046875 194.8428802490234 200.2921142578125 C 194.8428802490234 200.2863311767578 194.8428802490234 200.2863311767578 194.8371124267578 200.2805786132812 C 194.5801544189453 199.7707061767578 194.0664978027344 199.4770355224609 193.5347747802734 199.4769897460938 M 193.5346374511719 199.3769989013672 L 193.5347900390625 199.3769989013672 C 194.1221313476562 199.3770446777344 194.6520233154297 199.7014007568359 194.9207458496094 200.2244110107422 C 194.9281311035156 200.2340698242188 194.9348754882812 200.2458801269531 194.9389190673828 200.2608489990234 C 195.3080596923828 201.0228729248047 194.9945983886719 201.9469757080078 194.2354278564453 202.3265533447266 C 194.0165863037109 202.437744140625 193.7812347412109 202.4942626953125 193.5364990234375 202.4942626953125 C 192.9450225830078 202.4942626953125 192.4117736816406 202.165283203125 192.1448669433594 201.6357116699219 C 191.9573669433594 201.2666778564453 191.9252777099609 200.8460693359375 192.0546569824219 200.45166015625 C 192.1847076416016 200.0551452636719 192.4620513916016 199.7331237792969 192.8355560302734 199.5448608398438 C 193.0546875 199.4335327148438 193.2899932861328 199.3769989013672 193.5346374511719 199.3769989013672 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44407'
							data-name='Path 44407'
							transform='translate(-245.995 -197.09)'
							fill='#0033ad'>
							<path
								d='M 261.9220275878906 210.9250335693359 C 261.9219970703125 210.9250335693359 261.9220275878906 210.9250335693359 261.9219970703125 210.9250335693359 C 261.7453918457031 210.9250335693359 261.5731201171875 210.8735809326172 261.4238586425781 210.7762298583984 C 261.222412109375 210.6477661132812 261.0833129882812 210.4476928710938 261.0326843261719 210.2131500244141 C 260.9811706542969 209.974609375 261.0267944335938 209.7298278808594 261.1611328125 209.5238647460938 C 261.3253479003906 209.2663421630859 261.6073913574219 209.1123199462891 261.9153442382812 209.1123199462891 C 262.0919494628906 209.1123199462891 262.2642211914062 209.1637878417969 262.4134826660156 209.2611541748047 C 262.6175231933594 209.3942108154297 262.7570190429688 209.598388671875 262.8062133789062 209.8360290527344 C 262.8551330566406 210.0722808837891 262.8088989257812 210.3129730224609 262.676025390625 210.5137634277344 C 262.5119934082031 210.7710266113281 262.2299499511719 210.9250335693359 261.9220275878906 210.9250335693359 Z'
								stroke='none'
							/>
							<path
								d='M 261.9153442382812 209.1623382568359 C 261.6361389160156 209.1623229980469 261.3639526367188 209.2987823486328 261.2030029296875 209.5511779785156 C 260.9432678222656 209.9494323730469 261.0529174804688 210.4804077148438 261.451171875 210.7343444824219 C 261.8494262695312 210.9940643310547 262.38037109375 210.8843688964844 262.6343383789062 210.4861755371094 C 262.89404296875 210.0937042236328 262.7843933105469 209.5627288818359 262.3861694335938 209.3030242919922 C 262.2403259277344 209.2079162597656 262.07666015625 209.1623382568359 261.9153442382812 209.1623382568359 M 261.9153137207031 209.0623321533203 L 261.9153137207031 209.0623321533203 C 262.1016845703125 209.0623321533203 262.2833862304688 209.1166076660156 262.4407958984375 209.2192687988281 C 262.6560974121094 209.3596649169922 262.8032531738281 209.5751037597656 262.8551940917969 209.8258972167969 C 262.9068298339844 210.0752868652344 262.8580017089844 210.3293762207031 262.7177429199219 210.5413513183594 C 262.544921875 210.8123931884766 262.2471313476562 210.9750366210938 261.9219970703125 210.9750366210938 C 261.7356872558594 210.9750366210938 261.5539855957031 210.9207763671875 261.3965454101562 210.8180999755859 C 261.1841735839844 210.6826934814453 261.0372924804688 210.4713897705078 260.9837951660156 210.2236938476562 C 260.9294738769531 209.9720153808594 260.9775695800781 209.7137908935547 261.1192321777344 209.4965515136719 C 261.2924194335938 209.2249755859375 261.5902099609375 209.0623321533203 261.9153137207031 209.0623321533203 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44408'
							data-name='Path 44408'
							transform='translate(-289.449 -151.122)'
							fill='#0033ad'>
							<path
								d='M 307.9083862304688 161.8856201171875 C 307.9083557128906 161.8856201171875 307.9083862304688 161.8856201171875 307.9083557128906 161.8856201171875 C 307.8936157226562 161.8856201171875 307.8787841796875 161.8852081298828 307.8639221191406 161.8843383789062 C 307.6551208496094 161.8724060058594 307.4640808105469 161.7809600830078 307.3260192871094 161.6268310546875 C 307.187744140625 161.4724884033203 307.1181640625 161.2725982666016 307.1300659179688 161.06396484375 C 307.1536560058594 160.6517181396484 307.4939270019531 160.3287963867188 307.90478515625 160.3287963867188 C 307.9198913574219 160.3287963867188 307.9351501464844 160.3292388916016 307.950439453125 160.3301086425781 C 308.1593017578125 160.342041015625 308.350341796875 160.4334869384766 308.4884033203125 160.5876159667969 C 308.6266784667969 160.741943359375 308.6962585449219 160.9418487548828 308.6843566894531 161.1504669189453 C 308.6604309082031 161.5627136230469 308.319580078125 161.8856201171875 307.9083862304688 161.8856201171875 Z'
								stroke='none'
							/>
							<path
								d='M 307.9083557128906 161.8356323242188 C 308.2888488769531 161.8356475830078 308.6121215820312 161.5319366455078 308.6344299316406 161.1476135253906 C 308.6575012207031 160.7436218261719 308.3516235351562 160.4030914306641 307.9476013183594 160.3800201416016 C 307.543212890625 160.3566284179688 307.2030944824219 160.662841796875 307.1799926757812 161.0668182373047 C 307.1569213867188 161.4708251953125 307.4627990722656 161.8113403320312 307.8668212890625 161.8344268798828 C 307.8807067871094 161.8352355957031 307.8945922851562 161.8356323242188 307.9083557128906 161.8356323242188 M 307.9083862304688 161.9356231689453 C 307.8926696777344 161.9356231689453 307.8768615722656 161.9351806640625 307.8609924316406 161.9342498779297 C 307.6389770507812 161.9215698242188 307.4357299804688 161.8242492675781 307.2887573242188 161.6601867675781 C 307.1415710449219 161.4958801269531 307.0674743652344 161.2831115722656 307.0801696777344 161.0611114501953 C 307.106201171875 160.6063232421875 307.4937438964844 160.2539215087891 307.9533081054688 160.2801818847656 C 308.1754150390625 160.2928771972656 308.3786926269531 160.3901977539062 308.5256652832031 160.5542449951172 C 308.6728515625 160.7185668945312 308.7469482421875 160.9313201904297 308.7342529296875 161.1533203125 C 308.7088317871094 161.592041015625 308.3460693359375 161.9356231689453 307.9083862304688 161.9356231689453 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<path
							id='Path_44409'
							data-name='Path 44409'
							d='M359.188,164.683a.471.471,0,1,0,.444.5A.475.475,0,0,0,359.188,164.683Z'
							transform='translate(-337.989 -155.177)'
							fill='#0033ad'
							stroke='#fff'
							stroke-width='0.1'
						/>
						<g
							id='Path_44410'
							data-name='Path 44410'
							transform='translate(-299.948 -229.936)'
							fill='#0033ad'>
							<path
								d='M 318.9180908203125 245.2684326171875 C 318.9180603027344 245.2684326171875 318.9180603027344 245.2684326171875 318.9180297851562 245.2684326171875 C 318.7922058105469 245.2684326171875 318.6695251464844 245.2321319580078 318.563232421875 245.1634826660156 C 318.2663879394531 244.9655914306641 318.1823120117188 244.5619354248047 318.3752136230469 244.2632751464844 C 318.4976806640625 244.0796051025391 318.701416015625 243.9703063964844 318.920654296875 243.9703063964844 C 319.0464477539062 243.9703063964844 319.1691589355469 244.006591796875 319.2754516601562 244.0752563476562 C 319.421630859375 244.1696472167969 319.5217590332031 244.3160552978516 319.5573425292969 244.4874725341797 C 319.5928039550781 244.658203125 319.559326171875 244.8317260742188 319.4630737304688 244.97607421875 C 319.3410339355469 245.1591339111328 319.1372985839844 245.2684326171875 318.9180908203125 245.2684326171875 Z'
								stroke='none'
							/>
							<path
								d='M 318.920654296875 244.0203094482422 C 318.7256469726562 244.0202789306641 318.5343322753906 244.11474609375 318.4172058105469 244.2904052734375 C 318.2383117675781 244.5674133300781 318.3133544921875 244.9367980957031 318.5903625488281 245.1214904785156 C 318.8674621582031 245.3004302978516 319.2367553710938 245.2253265380859 319.4214782714844 244.9483337402344 C 319.6061706542969 244.6712951660156 319.5253601074219 244.296142578125 319.2483215332031 244.1172485351562 C 319.1469421386719 244.0517730712891 319.0331726074219 244.0203094482422 318.920654296875 244.0203094482422 M 318.9206237792969 243.9203033447266 L 318.9206237792969 243.9203033447266 C 319.0561218261719 243.9203033447266 319.1881713867188 243.9593658447266 319.3025817871094 244.0332489013672 C 319.4601135253906 244.1349639892578 319.5679626464844 244.2926635742188 319.6063232421875 244.4773101806641 C 319.6445007324219 244.6612548828125 319.6083984375 244.8482208251953 319.5046691894531 245.0037994384766 C 319.3733215332031 245.2008209228516 319.1540222167969 245.3184356689453 318.9180297851562 245.3184356689453 C 318.7825927734375 245.3184356689453 318.6505126953125 245.2793731689453 318.5361022949219 245.2054901123047 C 318.216064453125 244.9921264648438 318.1255798339844 244.5576324462891 318.3331909179688 244.2361602783203 C 318.4653625488281 244.0379180908203 318.6846618652344 243.9203033447266 318.9206237792969 243.9203033447266 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<path
							id='Path_44411'
							data-name='Path 44411'
							d='M90.683,320.63a.471.471,0,1,0,.133.652h0A.463.463,0,0,0,90.683,320.63Z'
							transform='translate(-84.761 -302.051)'
							fill='#0033ad'
							stroke='#fff'
							stroke-width='0.1'
						/>
						<path
							id='Path_44412'
							data-name='Path 44412'
							d='M269.6,320.25a.472.472,0,0,0,.427.843.472.472,0,0,0-.427-.843Z'
							transform='translate(-253.794 -301.716)'
							fill='#0033ad'
							stroke='#fff'
							stroke-width='0.1'
						/>
						<g
							id='Path_44413'
							data-name='Path 44413'
							transform='translate(-124.889 -188.063)'
							fill='#0033ad'>
							<path
								d='M 134.0133819580078 202.5483245849609 C 134.0133514404297 202.5483245849609 134.0133819580078 202.5483245849609 134.0133514404297 202.5483245849609 L 133.995849609375 202.5482330322266 C 133.7006530761719 202.5482330322266 133.4157409667969 202.4635467529297 133.1724700927734 202.3033599853516 C 132.8344421386719 202.0809020996094 132.6028900146484 201.7417449951172 132.5204925537109 201.3484039306641 C 132.4379577636719 200.9543762207031 132.5148010253906 200.551513671875 132.7368774414062 200.2140655517578 C 133.0177154541016 199.787353515625 133.4898681640625 199.5326080322266 133.9998931884766 199.5326080322266 C 134.2928314208984 199.5326080322266 134.5784606933594 199.6175689697266 134.8259124755859 199.7783050537109 C 135.5186462402344 200.2342071533203 135.7141418457031 201.1713562011719 135.2619323730469 201.8675231933594 C 134.9836120605469 202.2938385009766 134.5168609619141 202.5483245849609 134.0133819580078 202.5483245849609 Z'
								stroke='none'
							/>
							<path
								d='M 134.0133514404297 202.4983215332031 C 134.5031585693359 202.4983367919922 134.9518127441406 202.2510986328125 135.2200012207031 201.8402862548828 C 135.6586303710938 201.1650085449219 135.4739532470703 200.2646484375 134.7986755371094 199.8202362060547 C 134.1233978271484 199.3816223144531 133.2230529785156 199.5662994384766 132.7786560058594 200.2415618896484 C 132.3342590332031 200.9168243408203 132.5247039794922 201.8171997070312 133.1999664306641 202.2615966796875 C 133.4365997314453 202.4174346923828 133.7136383056641 202.4982299804688 133.9964294433594 202.4982299804688 C 134.0020599365234 202.498291015625 134.0077362060547 202.4983215332031 134.0133514404297 202.4983215332031 M 134.0133819580078 202.5983276367188 L 133.9951782226562 202.5982208251953 C 133.6908569335938 202.5982360839844 133.3964233398438 202.5107116699219 133.1449584960938 202.3451232910156 C 132.4253234863281 201.8715057373047 132.2235107421875 200.9031829833984 132.6951293945312 200.1865844726562 C 132.9852294921875 199.7457733154297 133.4729919433594 199.4826049804688 133.9998931884766 199.4826049804688 C 134.3025207519531 199.4826049804688 134.5975646972656 199.5703582763672 134.8531494140625 199.7363739013672 C 135.5690307617188 200.2075042724609 135.77099609375 201.1756134033203 135.3038635253906 201.8947601318359 C 135.0162048339844 202.3353881835938 134.5338287353516 202.5983276367188 134.0133819580078 202.5983276367188 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44414'
							data-name='Path 44414'
							transform='translate(-102.651 -259.447)'
							fill='#0033ad'>
							<path
								d='M 109.6652526855469 276.8445739746094 C 109.6652145385742 276.8445739746094 109.6652221679688 276.8445739746094 109.6651916503906 276.8445739746094 C 109.5127716064453 276.8445739746094 109.3650970458984 276.8005981445312 109.238166809082 276.7174072265625 C 109.0652313232422 276.6041259765625 108.946891784668 276.4288635253906 108.9049301147461 276.2239074707031 C 108.8630676269531 276.0194091796875 108.9024963378906 275.8114013671875 109.0159759521484 275.63818359375 C 109.1592864990234 275.4194641113281 109.4031066894531 275.2888488769531 109.6681976318359 275.2888488769531 C 109.8206100463867 275.2888488769531 109.9682693481445 275.3328247070312 110.09521484375 275.416015625 C 110.2704620361328 275.5288696289062 110.390380859375 275.7038269042969 110.4325714111328 275.908447265625 C 110.4748458862305 276.113525390625 110.4338302612305 276.3220825195312 110.3170776367188 276.4957275390625 C 110.1741027832031 276.7139587402344 109.930290222168 276.8445739746094 109.6652526855469 276.8445739746094 Z'
								stroke='none'
							/>
							<path
								d='M 109.6681976318359 275.3388671875 C 109.4303970336914 275.3388366699219 109.1966781616211 275.45361328125 109.0578002929688 275.6655883789062 C 108.8384857177734 276.0003356933594 108.9308319091797 276.456298828125 109.2655715942383 276.6755981445312 C 109.6003265380859 276.8949584960938 110.0562744140625 276.8025817871094 110.2755889892578 276.4678344726562 C 110.5006713867188 276.133056640625 110.4083251953125 275.6771545410156 110.0678176879883 275.4578247070312 C 109.9450454711914 275.3773803710938 109.8059158325195 275.3388671875 109.6681976318359 275.3388671875 M 109.6681671142578 275.2388610839844 L 109.6681747436523 275.2388610839844 C 109.8303756713867 275.2388610839844 109.9875183105469 275.2856750488281 110.1226196289062 275.3741760253906 C 110.3089218139648 275.4941711425781 110.4366149902344 275.6804809570312 110.4815368652344 275.8983459472656 C 110.5265502929688 276.1166687011719 110.4828796386719 276.3387451171875 110.3585815429688 276.5236206054688 C 110.2066497802734 276.7555236816406 109.9471969604492 276.8945617675781 109.6651992797852 276.8945617675781 C 109.5030212402344 276.8945617675781 109.3458786010742 276.8477783203125 109.2107696533203 276.7592468261719 C 109.026611328125 276.6386108398438 108.9005966186523 276.4520263671875 108.8559494018555 276.2339477539062 C 108.8114166259766 276.0164184570312 108.853401184082 275.7951049804688 108.9741516113281 275.6107788085938 C 109.1267395019531 275.3778991699219 109.3861770629883 275.2388610839844 109.6681671142578 275.2388610839844 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44415'
							data-name='Path 44415'
							transform='translate(-166.195 -306.888)'
							fill='#0033ad'>
							<path
								d='M 176.9747009277344 326.937744140625 C 176.9746704101562 326.937744140625 176.9746551513672 326.937744140625 176.9746398925781 326.937744140625 C 176.9630584716797 326.937744140625 176.9513549804688 326.9374389648438 176.9396514892578 326.9368286132812 C 176.5764770507812 326.9113464355469 176.3019409179688 326.6025695800781 176.3267822265625 326.2485046386719 C 176.3509063720703 325.9048156738281 176.6377410888672 325.6355590820312 176.9797821044922 325.6355590820312 C 176.9911804199219 325.6355590820312 177.0026702880859 325.6358642578125 177.0141754150391 325.636474609375 C 177.3710021972656 325.6615295410156 177.6455688476562 325.9724426269531 177.6270751953125 326.3296203613281 C 177.6031341552734 326.6710510253906 177.3166046142578 326.937744140625 176.9747009277344 326.937744140625 Z'
								stroke='none'
							/>
							<path
								d='M 176.9797973632812 325.6855773925781 C 176.6645355224609 325.685546875 176.3990020751953 325.9336242675781 176.3766632080078 326.2520141601562 C 176.3535766601562 326.5809631347656 176.6132965087891 326.8638000488281 176.9422760009766 326.8869018554688 C 177.2710876464844 326.904541015625 177.5540466308594 326.656005859375 177.5771484375 326.3270263671875 C 177.5944671630859 325.9923095703125 177.3405151367188 325.7095031738281 177.0115356445312 325.6864013671875 C 177.0009155273438 325.6858520507812 176.9903106689453 325.6855773925781 176.9797973632812 325.6855773925781 M 176.9797668457031 325.5855712890625 C 176.9920654296875 325.5855712890625 177.0044250488281 325.5858764648438 177.0168151855469 325.5865478515625 C 177.4015045166016 325.6135559082031 177.6968994140625 325.947998046875 177.6770172119141 326.3322143554688 C 177.6503143310547 326.7130737304688 177.3207855224609 327.0079650878906 176.9370269775391 326.9867553710938 C 176.5454864501953 326.9592895507812 176.2501220703125 326.6265869140625 176.2769165039062 326.2450256347656 C 176.3028564453125 325.875244140625 176.6115875244141 325.5855712890625 176.9797668457031 325.5855712890625 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44416'
							data-name='Path 44416'
							transform='translate(-161.952 -245.344)'
							fill='#0033ad'>
							<path
								d='M 172.7250823974609 262.1332092285156 C 172.7250518798828 262.1332092285156 172.7250823974609 262.1332092285156 172.7250518798828 262.1332092285156 C 172.7098999023438 262.1332092285156 172.6946411132812 262.1328430175781 172.6793670654297 262.1321105957031 C 172.4365081787109 262.1202697753906 172.2135467529297 262.01416015625 172.0515594482422 261.8333740234375 C 171.8899841308594 261.6530456542969 171.8091278076172 261.4205627441406 171.8238677978516 261.1787414550781 C 171.8472900390625 260.6986694335938 172.2415924072266 260.3221740722656 172.7215118408203 260.3221740722656 C 172.7399291992188 260.3221740722656 172.7585296630859 260.3227233886719 172.7771759033203 260.3238525390625 C 173.0182495117188 260.3356323242188 173.2408447265625 260.4420776367188 173.4033508300781 260.6235656738281 C 173.565673828125 260.8048706054688 173.6468811035156 261.0369873046875 173.6320648193359 261.2771911621094 C 173.6086578369141 261.7568969726562 173.2102355957031 262.1332092285156 172.7250823974609 262.1332092285156 Z'
								stroke='none'
							/>
							<path
								d='M 172.7215118408203 260.3721618652344 C 172.2712554931641 260.3721313476562 171.8960113525391 260.7261047363281 171.873779296875 261.1817626953125 C 171.8449249267578 261.655029296875 172.2085266113281 262.05908203125 172.6817932128906 262.0821533203125 C 173.1550140380859 262.1048889160156 173.5590515136719 261.7474060058594 173.5821533203125 261.2741088867188 C 173.6110076904297 260.806640625 173.2473907470703 260.3968505859375 172.7741394042969 260.373779296875 C 172.7565155029297 260.3727111816406 172.7389221191406 260.3721618652344 172.7215118408203 260.3721618652344 M 172.7214508056641 260.2721557617188 C 172.7409362792969 260.2721557617188 172.7605438232422 260.2727661132812 172.7801971435547 260.2739562988281 C 173.0341491699219 260.2863464355469 173.2691040039062 260.398681640625 173.4406127929688 260.5902404785156 C 173.6119079589844 260.7815551757812 173.6976165771484 261.026611328125 173.6819610595703 261.2802734375 C 173.6565246582031 261.8017883300781 173.2097930908203 262.2079162597656 172.6768951416016 262.1820373535156 C 172.4206237792969 262.1695251464844 172.185302734375 262.0575561523438 172.0143127441406 261.8667297363281 C 171.84375 261.6763916015625 171.7583923339844 261.4309692382812 171.7739715576172 261.1756896972656 C 171.7986450195312 260.6695556640625 172.2148590087891 260.2721557617188 172.7214508056641 260.2721557617188 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
						<g
							id='Path_44417'
							data-name='Path 44417'
							transform='translate(-227.377 -259.152)'
							fill='#0033ad'>
							<path
								d='M 242.0361022949219 276.5340576171875 C 242.0360717773438 276.5340576171875 242.0360565185547 276.5340576171875 242.0360260009766 276.5340576171875 C 241.7425384521484 276.5340270996094 241.4761505126953 276.3711853027344 241.3408508300781 276.1090393066406 C 241.1445617675781 275.7286682128906 241.2966156005859 275.25830078125 241.6797943115234 275.0604858398438 C 241.7907867431641 275.0041198730469 241.9092712402344 274.9756164550781 242.0322570800781 274.9756164550781 C 242.3283996582031 274.9756164550781 242.5973052978516 275.1402282714844 242.7340545654297 275.4052429199219 C 242.826904296875 275.5908813476562 242.8419952392578 275.8014221191406 242.7767944335938 275.9986267089844 C 242.7115020751953 276.1961059570312 242.5737152099609 276.3558044433594 242.3888092041016 276.4482421875 C 242.2786865234375 276.5050659179688 242.1598205566406 276.5340576171875 242.0361022949219 276.5340576171875 Z'
								stroke='none'
							/>
							<path
								d='M 242.0322570800781 275.0256042480469 C 241.9214630126953 275.0256042480469 241.8089141845703 275.0509948730469 241.7027282714844 275.1049194335938 C 241.3448791503906 275.2896423339844 241.2006225585938 275.728271484375 241.3852844238281 276.0861206054688 C 241.5699920654297 276.4439392089844 242.0086059570312 276.5881652832031 242.366455078125 276.4035339355469 C 242.7242736816406 276.224609375 242.8685607910156 275.7859497070312 242.6896362304688 275.4281616210938 C 242.5588989257812 275.1748352050781 242.3008422851562 275.0256042480469 242.0322570800781 275.0256042480469 M 242.0322570800781 274.9255981445312 C 242.3471984863281 274.9255981445312 242.6331329345703 275.1006164550781 242.7785034179688 275.3822937011719 C 242.8775939941406 275.5804443359375 242.8936462402344 275.8044738769531 242.8242645263672 276.0143127441406 C 242.7547760009766 276.2245178222656 242.6080627441406 276.39453125 242.4111785888672 276.4929809570312 C 242.2944641113281 276.55322265625 242.1678771972656 276.5840454101562 242.0360260009766 276.5840454101562 C 241.7237548828125 276.5840454101562 241.4403533935547 276.4108276367188 241.2964172363281 276.1319885253906 C 241.0874938964844 275.7271118164062 241.2491760253906 275.2265014648438 241.6568603515625 275.0160522460938 C 241.7752075195312 274.9559326171875 241.9013214111328 274.9255981445312 242.0322570800781 274.9255981445312 Z'
								stroke='none'
								fill='#fff'
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default CardanoIcon;
